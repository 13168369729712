import React, { useState, useEffect } from "react";
import Topbar from "../../Components/Topbar";
// import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import DeleteResultPopup from "./DeleteResultPopup";
import Loader from "../../Components/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaveUser from "./SaveUser";
import EditDatePopup from "./EditDatePopup";
import EditDateResponsePopUP from "./EditDateResponse";
import UserAccessPopUp from "./UserAccessPopUp";

const Main = () => {
  const [SavedResults, setSavedResults] = useState([]);
  const [DeletedId, setDeletedId] = useState("");
  const [EditId, setEditId] = useState("");
  //   const [Search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [GetData, setGetData] = useState(true);

  const [ShowForm, setShowForm] = useState(false);
  const [SaveResponse, setSaveResponse] = useState("");
  // const [UserId, setUserId] = useState('');
  const [FirstName, setFirstName] = useState("");
  const [FirstNameError, setFirstNameError] = useState("");
  const [LastName, setLastName] = useState("");
  const [LastNameError, setLastNameError] = useState("");
  const [UserName, setUserName] = useState("");
  const [UserNameError, setUserNameError] = useState("");
  const [Password, setPassword] = useState("");
  const [PasswordError, setPasswordError] = useState("");
  const [CPassword, setCPassword] = useState("");
  const [CPasswordError, setCPasswordError] = useState("");
  const [Active, setActive] = useState(false);
  const [DeactivationDate, setDeactivationDate] = useState("");
  const [DeactivationDateError, setDeactivationDateError] = useState("");
  const [EditDateFlag, setEditDateFlag] = useState(false);
  const [EditDateId, setEditDateId] = useState("");
  const [EditDate, setEditDate] = useState("");
  const [EditDateError, setEditDateError] = useState("");
  const [EditDateResponse, setEditDateResponse] = useState("");

  const [UserAccess, setUserAccess] = useState({});

  useEffect(() => {
    if (GetData) {
      setLoader(true);
      axios.post("get_user/", {}).then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
        } else {
          setSavedResults(response.data.data);
          setGetData(false);
        }
        setLoader(false);
      });
    }
  }, [GetData]);

  const DeleteId = (id) => {
    setDeletedId(id);
  };

  const CanceledId = () => {
    setDeletedId("");
    setEditId("");
  };
  const DeleteRecord = (id) => {
    setLoader(true);
    axios.post("delete_user/", { userid: DeletedId }).then((response) => {
      if (response.data.error === 1) {
        toast.error(response.data.erroMsg);
        setDeletedId("");
      } else {
        setDeletedId(response.data.data);
        setDeletedId("");
        setGetData(true);
      }
      setLoader(false);
    });
  };

  useEffect(() => {
    if (!ShowForm) {
      setSaveResponse("");
      // setUserId('')
      setFirstName("");
      setLastName("");
      setUserName("");
      setPassword("");
      setCPassword("");
      setDeactivationDate("");
      setActive(false);
      setEditId("");
    }
  }, [ShowForm]);

  const validate = () => {
    setFirstNameError("");
    setLastNameError("");
    setUserNameError("");
    setPasswordError("");
    setCPasswordError("");
    setDeactivationDateError("");

    let flag = true;

    if (FirstName === "") {
      setFirstNameError("Please enter First Name");
      flag = false;
    }

    if (LastName === "") {
      setLastNameError("Please enter Last Name");
      flag = false;
    }

    if (UserName === "") {
      setUserNameError("Please enter User Name");
      flag = false;
    }

    if (Password === "" && EditId === "") {
      setPasswordError("Please enter Password");
      flag = false;
    }

    if (CPassword !== Password) {
      setCPasswordError("Confirm Password should be same as Password");
      flag = false;
    }

    if (DeactivationDate === "") {
      setDeactivationDateError("Please enter Deactivation Date");
      flag = false;
    }
    return flag;
  };

  const SaveUserData = () => {
    let objectDate = new Date(DeactivationDate);
    let day = objectDate.getDate();
    let month = objectDate.getMonth();
    month = month + 1;
    let year = objectDate.getFullYear();

    if (validate()) {
      setLoader(true);
      axios
        .post("create_user/", {
          firstname: FirstName,
          lastname: LastName,
          username: UserName,
          password: Password,
          deactivate_date: year + "-" + month + "-" + day,
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
          } else {
            setSaveResponse(response.data.data);
            setGetData(true);
          }
          setLoader(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  };

  useEffect(() => {
    if (EditId !== "") {
      // setLoader(true);
      axios
        .post("get_user/", {})
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
          } else {
            setFirstName("");
            setLastName("");
            setUserName("");
            // setPassword("");
            // setCPassword("");
            setDeactivationDate("");
            setShowForm(true);
          }
          setLoader(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [EditId]);

  const GetEditID = (id) => {
    setEditDateFlag(!EditDateFlag);
    setEditDateId(id);
  };

  const SaveEditDate = () => {
    let objectDate = new Date(EditDate);
    let day = objectDate.getDate();
    let month = objectDate.getMonth();
    month = month + 1;
    let year = objectDate.getFullYear();

    let flag = true;

    if (EditDate === "") {
      setEditDateError("Please enter Deactivation Date");
      flag = false;
    }

    if (flag) {
      setLoader(true);
      axios
        .post("update_deactivate_date/", {
          userid: EditDateId,
          deactivate_date: year + "-" + month + "-" + day,
        })
        .then((response) => {
          if (response.data.error === 1) {
            toast.error(response.data.erroMsg);
          } else {
            setEditDateResponse(response.data.data);
            setGetData(true);
            setEditDateFlag(!EditDateFlag);
          }
          setLoader(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    } else {
      setGetData(true);
    }
  };

  return (
    <div>
      <ToastContainer theme="colored" />
      {loader ? <Loader /> : null}
      <Topbar BrandName={""} />
      {/* <Menubar /> */}
      {DeletedId !== "" ? (
        <DeleteResultPopup
          CanceledId={CanceledId}
          DeleteRecord={DeleteRecord}
        />
      ) : null}

      {Object.keys(UserAccess).length !== 0 ? (
        <UserAccessPopUp UserAccess={UserAccess} setUserAccess={setUserAccess}
        />
      ) : null}

      {EditDateResponse !== "" ? (
        <EditDateResponsePopUP EditDateResponse={EditDateResponse} setEditDateResponse={setEditDateResponse} />
      ) : null}
      {EditDateFlag ? (
        <EditDatePopup
          EditDateFlag={EditDateFlag}
          setEditDateFlag={setEditDateFlag}
          SaveEditDate={SaveEditDate}
          setEditDate={setEditDate}
          EditDateError={EditDateError}
        />
      ) : null}
      {ShowForm ? (
        <SaveUser
          setShowForm={setShowForm}
          FirstNameError={FirstNameError}
          FirstName={FirstName}
          setFirstName={setFirstName}
          LastNameError={LastNameError}
          LastName={LastName}
          setLastNameError={setLastNameError}
          setLastName={setLastName}
          UserNameError={UserNameError}
          UserName={UserName}
          setUserName={setUserName}
          PasswordError={PasswordError}
          Password={Password}
          setPassword={setPassword}
          CPasswordError={CPasswordError}
          CPassword={CPassword}
          setCPassword={setCPassword}
          Active={Active}
          setActive={setActive}
          DeactivationDate={DeactivationDate}
          setDeactivationDate={setDeactivationDate}
          DeactivationDateError={DeactivationDateError}
          SaveResponse={SaveResponse}
          SaveUserData={SaveUserData}
        />
      ) : null}

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">Users</h1>
                </div>
              </div>
              <div className="col-sm-6" style={{ textAlign: "right" }}>
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={() => setShowForm(true)}
                >
                  Add User
                </button>
              </div>
            </div>
          </div>
          <div
            className="container-fluid main-content-w"
            style={{ paddingTop: "10px" }}
          >
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="row justify-content-between mx-2">
                    <div className="col-sm-12">
                      <div className="page-title-box d-sm-flex align-items-center flex-row-reverse p-0">
                        <div className="page-title-right">
                          <form className="app-search d-none d-lg-block">
                            <div className="position-relative">
                              
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-between mx-2">
                    <div className="col-sm-12">
                      <div className="table-responsive saved-result-table">
                        <table className="table table-striped mb-0">
                          <thead>
                            <tr>
                              <th>Serial No</th>
                              <th>Name</th>
                              <th>User</th>
                              <th>Deactivation Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {SavedResults
                              ? SavedResults.map((item, i) => {
                                return (
                                  <tr key={item.id}>
                                    <td>{i + 1}</td>
                                    <td>
                                      {item.firstname} {item.lastname}
                                    </td>
                                    <td>{item.username}</td>
                                    <td>
                                      {item.deactivation_date}{" "}
                                      <i
                                        title="Edit Deactivation Date"
                                        className="ri-edit-box-line p-2"
                                        onClick={() => GetEditID(item.id)}
                                      ></i>
                                    </td>
                                    <td className="delete-result-icon">
                                     
                                      <i
                                        title="Delete User"
                                        className="ri-delete-bin-7-line"
                                        onClick={() => DeleteId(item.id)}
                                      ></i>
                                      < i
                                        title="User Access"
                                        className="ri-key-fill m-3"
                                        onClick={() => setUserAccess(item)}
                                      >
                                      </i>
                                    </td>
                                  </tr>
                                );
                              })
                              : null}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
