import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import axiosDownload from "../../axiosDownload";
import LineChart from "./LineChart";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";
import Download from "../../images/download-icon.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SummaryAdstock from "./SummaryAdstock";
import PopUp from "../../Components/PopUp";
import { Tooltip } from 'react-tooltip'
import InfoIcon from '../../images/icons8-info.svg'

import LightThemeHexCode from "../LightThemeHexCode";
import useDownloadChart from "../../hooks/useDownloadChart";
import axiosChartDownload from "../../axiosChartDownload";
import domtoimage from "dom-to-image";
import downloadFlag from "../downloadFlag";



const Main = () => {
  const refGraphDiv = useRef(null);
  const RowWidthRef = useRef(null);
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  const [loader, setLoader] = useState(false);
  const [ChannelAll, setChannelAll] = useState([]);
  // console.log("ChannelAll: ", ChannelAll);
  const [SelectedChannel, setSelectedChannel] = useState("");
  // console.log("SelectedChannel: ", SelectedChannel);
  const [ROCData, setROCData] = useState({});
  // console.log("ROCData: ", ROCData);
  const [AdstockData, setAdstockData] = useState({});
  const [Summary, setSummary] = useState([]);
  const [FooterNotes, setFooterNotes] = useState('');

  const [ChartData, setChartData] = useState([]);
  // console.log("ChartData: ", ChartData);
  const [SelectedChartData, setSelectedChartData] = useState({});
  // console.log("SelectedChartData: ", SelectedChartData);
  const [FetchCall, setFetchCall] = useState(false);

  //Dropdown
  const [Fixed, setFixed] = useState([]);
  // console.log("Fixed: ", Fixed);
  const [Sortable, setSortable] = useState([]);
  // console.log("Sortable: ", Sortable);
  const [HeirarchyData, setHeirarchyData] = useState({});
  // console.log("HeirarchyData: ", HeirarchyData);
  const [Downloadchart, setDownloadchart] = useState(false)

  const [Notes, setNotes] = useState('');
  const [ShowSummary, setShowSummary] = useState(0)

  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");
  const [payloadChartData, setPayloadChartData] = useState({});
  const [tabName, setTabName] = useState([]);

  const [onChangeValue, setOnchangeValue] = useState("");

  const [searchQuery, setSearchQuery] = useState('');
  const [filteredChannels, setFilteredChannels] = useState(ChannelAll);

  // console.log("tabName: ", tabName);
  

  const userName = localStorage.getItem("NameSkewb");



  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    Fixed,
    Sortable,
    setLoader,
    Analyse.Brand,
    "",
    "",
    "",
    setIsDownloadingChart,
    Analyse.KPI
  );

  useEffect(() => {
    setFetchCall(true);
  }, [Analyse.Brand, Analyse.KPI])

  useEffect(() => {
    const url = window.location.href;
    const text = url.split('/');
    const tab = text[text.length - 1];
    for (var i = 0; i < Analyse.Menubar.length; i++) {
      if (Analyse.Menubar[i].key === tab) {
        setNotes(Analyse.Menubar[i].notes);
      }
    }
  }, [Analyse])

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_hirarchy_dropdown/", {
        hirarchy: Analyse.Brand,
        page: "roc"
      })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response.data: ", response.data);
          var arr = []
          for (var k = 0; k < response.data.data.sortable.length; k++) {
            var obj = response.data.data.sortable[k]
            obj["default_value"] = response.data.data.sortable[k]["options"][0]["value"]
            arr.push(obj)
          }
          setFixed(response.data.data.fixed);
          setSortable(arr)
          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });
  }, [Analyse.Brand]);

  useEffect(() => {
    if (Fixed.length !== 0) {
      setLoader(true);
      axios
        .post("get_roc_channels/", { brand: Analyse.Brand, kpi: Analyse.KPI, Hierarchy: HeirarchyData, filter_key: onChangeValue })
        .then((response) => {
          if (response.data.error !== 1) {
            setChannelAll(response.data.data);
            setFilteredChannels(response.data.data);
            setSelectedChannel(response.data.data[0].key);
            setFooterNotes(response.data.notes)
          } else {
            setChannelAll([]);
            setSelectedChannel('');
            setFooterNotes('')
            // toast.error(response.data.erroMsg);
          }
          // setSelectedChannel("Influencer")
          setFetchCall(true)
          setLoader(false);
        })
        .catch((data) => {
          setLoader(false);
        });
    }
  }, [Analyse.KPI, Analyse.Brand, Fixed, HeirarchyData, onChangeValue]);

  useEffect(() => {
    // const controller = new AbortController();
    if (SelectedChannel && FetchCall) {
      setLoader(true);
      setROCData({});
      setAdstockData({})
      setSummary([]);

      let payload = {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        channel: SelectedChannel,
        theme: localStorage.getItem("CurrentTheme"),
        LightThemeColorCode: [localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],

      }

      for (const property in HeirarchyData) {
        payload[property] = HeirarchyData[property]
      }

      axios
        .post("get_roc/", payload)
        .then((response) => {
          if (response.data.error === 1) {
            setROCData({});
            setSummary([]);
            setLoader(false);
            // toast.error(response.data.erroMsg);
            setFetchCall(false)
          } else {
            if (SelectedChannel === 'All Channels') {
              setROCData(response.data.data);
              setChartData([])
              setSelectedChartData({})
              setPayloadChartData(response.data.data);
              // setTabName(null);
            } else {
              setAdstockData(response.data.data.adstock)
              setChartData(response.data.data.chart_data)
              setSelectedChartData(response.data.data.chart_data[0])
              setPayloadChartData(response.data.data);
              setTabName(response.data.data.chart_data[0].tab_name);
            }
            setSummary(response.data.data.summary);
            if (response.data.data.show_summary !== undefined) {
              setShowSummary(response.data.data.show_summary)
            }

            setFetchCall(false)
            setLoader(false);
          }
        })
        .catch((data) => {
          setLoader(false);
        });
    }

  }, [SelectedChannel, Analyse.Brand, Analyse.KPI, HeirarchyData, FetchCall]);

  useEffect(() => {
    if (JSON.stringify(SelectedChartData) !== '{}') {
      setROCData(SelectedChartData.data);
    }
  }, [SelectedChartData]);


  // New api

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       // Check if ChannelAll array is not empty
  //       if (ChannelAll.length > 0) {
  //         const response = await axios.post('http://15.207.123.147:3000/roc_json/', {
  //           data: ChannelAll
  //           // Add more key-value pairs as needed
  //         });
  //         console.log(response.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  
  //   fetchData();
  // }, [ChannelAll]);
  

  const DownloadData = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("roc/", {
        data: payloadChartData,
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        // channel: SelectedChannel,
        // chartdata: HeirarchyData,

      })
      .then((response) => {
        // console.log("response roc: ", response.data);
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);

      }).catch((data) => {
        setLoader(false);
      });
  }

  const [ShowPopup, setShowPopup] = useState(false)

  const OnChangeHandler = (index, value) => {
    setOnchangeValue(value);
    var sortable = [...Sortable]
    sortable[index].default_value = value
    // setSortable(sortable);

    //Getting next levels
    let next_level_arr = [];
    for (var j = 0; j < sortable.length; j++) {
      if (j > index) {
        next_level_arr.push(sortable[j].level);
      }
    }
    const next_level = next_level_arr.toString().replaceAll(",", ";");

    //Getting befors selected  values
    let selection_value = [];
    var i = ((index) - 1);
    while (i >= 0) {
      if (sortable[i]['default_value']) {
        selection_value.push(sortable[i]['default_value'] === "all" ? "" : sortable[i]['default_value']);
      }
      i--;
    }
    const selection = selection_value.toString().replaceAll(",", ";");
    setLoader(true);
    axios
      .post("hirarchy_dropdown_child_options/", {
        hirarchy: Analyse.Brand,
        "selection": selection !== "all" ? selection : "",
        "next_level": next_level,
        "page": "roc",
        "curr_selection": value,
      })
      .then((response) => {
        var data = [...sortable]
        for (var i = 0; i < sortable.length; i++) {
          if (response.data.data[data[i].level]) {
            data[i]['options'] = response.data.data[data[i].level];
            data[i]['default_value'] = response.data.data[data[i].level][0].value;
          }
        }
        setSortable(data);
        setLoader(false);
      }).catch((data) => {
        setLoader(false);
      });
  }

  useEffect(() => {
    var chartdata = {}
    for (var k = 0; k < Fixed.length; k++) {
      chartdata[Fixed[k].id] = Fixed[k].value;
    }
    for (var l = 0; l < Sortable.length; l++) {
      chartdata[Sortable[l].name] = Sortable[l].default_value;
    }
    setHeirarchyData(chartdata)
    setFetchCall(true)
  }, [Fixed, Sortable])


  const [ChartHeight, setChartHeight] = useState("");
  useEffect(() => {
    if (refGraphDiv.current !== null) {
      setChartHeight(refGraphDiv.current.clientHeight);
    }
  }, [ROCData, SelectedChartData]);

  const [DivHeight, setDivHeight] = useState("");
  const DivRef = useRef(null)
  
  useEffect(() => {
    if (DivRef.current !== null) {
      setDivHeight(DivRef.current.clientHeight);
    }
  }, [ROCData]);

  // const downloadPDF = async () => {
  //   setLoader(true);
  //   // console.log("payloadChartData: ", payloadChartData?.chart_data[0]?.tab_name)


  //   const chartContainers = [
  //     document.getElementById("growth_curve_chart"),
  //   ];


  //   if (chartContainers.every((container) => container !== null)) {
  //     try {
  //       // setLoader(true);
  //       const dataUrls = await Promise.all(
  //         chartContainers.map((container) => domtoimage.toPng(container))
  //       );
  //       // setLoader(true);
  //       const response = await axiosDownload.post("roc/", {
  //         data: payloadChartData,
  //         download_type: "PDF",
  //       });
  //       // setLoader(false);
  //       console.log("excel: ", response.data);
  //       const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
  //         images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
          
  //         document: {
  //           table1_data: SelectedChannel === 'All Channels' ? response.data.table1_data : response.data[payloadChartData?.chart_data[0]?.tab_name],
  //           // table2_data: response.data.table2_data,
  //           footer_content: "Private & Confidential",
  //           table_heading1: "Growth Curves",
  //           image_heading1: "Growth Curves",
  //           // table_heading2: "",
  //           // image_heading2: "",
  //           username: userName,
  //           timeframe: "",
  //           start_date: "",
  //           end_date: "",
  //           titlename: "Growth Curves",
  //           category: "",
  //           chartdata: "",
  //           brand: Analyse.Brand,
  //           kpi: Analyse.KPI,
  //         },
  //       });
  //       if (pdfResponse.data.error === 1) {
  //         toast.error(pdfResponse.data.erroMsg);
  //         setLoader(false);
  //       } else {
  //         // console.log("response pdf: ", pdfResponse.data);
  //         setLoader(false);
  //         var link = document.createElement("a");
  //         link.target = "_blank";
  //         link.href = pdfResponse.data.pdf_url;
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //       }
  //     } catch (error) {
  //       setLoader(false);
  //       console.error("Error while generating pdf:", error);
  //     }
  //   }
  // };
  


  const payloadPDF = {
    footer_content: "Private & Confidential",
    table_heading1: "Growth Curves",
    image_heading1: "Growth Curves",
    username: userName,
    timeframe: "",
    start_date: "",
    end_date: "",
    titlename: "Growth Curves",
    category: "",
    chartdata: HeirarchyData,
    brand: Analyse.Brand,
    kpi: Analyse.KPI,
    tabname: "roc",
  };
  const downloadPDF = async (e) => {
    e.preventDefault();
    setLoader(true);
  
    const chartContainers = {};

    if(SelectedChannel === "All Channels") {
      chartContainers["All Channels"]=  document.getElementById("growth_curve_chart");
    } else {
      chartContainers[SelectedChartData.tab_name]=  document.getElementById("growth_curve_chart");
      chartContainers["Adstock"] = document.getElementById("adstock_chart");
    }
    
    const validChartContainers = Object.fromEntries(
      Object.entries(chartContainers).filter(([key, value]) => value !== null)
    );
  
    // console.log("Valid Chart Containers:", validChartContainers);

    // console.log("chartContainers: ", chartContainers);
  
    if (Object.values(validChartContainers).every((container) => container !== null)) {
      try {
        const dataUrls = await Promise.all(
          Object.entries(validChartContainers).map(async ([key, container]) => {
            const dataUrl = await domtoimage.toPng(container);
            return { [key]: dataUrl.split(",")[1] };
          })
        );
  
        const response = await axiosDownload.post("roc/", {
          data: payloadChartData,
          download_type: "PDF",
        });
  
        const imagesObject = Object.assign({}, ...dataUrls);

        // console.log("imagesObject: ", imagesObject);

        // SelectedChannel === "All Channels" ? payloadPDF["All Channles"] = response.data["All Channels"] : payloadPDF[tabName] = response.data[payloadChartData?.chart_data[0]?.tab_name];
        if (SelectedChannel === "All Channels") {
          payloadPDF["All Channels"] = response.data["All Channels"];
        } else {
          payloadPDF[tabName] = response.data[payloadChartData?.chart_data[0]?.tab_name];
          payloadPDF["Adstock"] = response.data.Adstock;
        }
        
        // console.log("payload pdf: ", payloadPDF);
        const pdfResponse = await axiosChartDownload.post("download_pdf/rocpdf/", {
          images: imagesObject,
          // document: {
          //   table1_data: SelectedChannel === 'All Channels' ? response.data["All Channels"] : response.data[payloadChartData?.chart_data[0]?.tab_name],
          //   footer_content: "Private & Confidential",
          //   table_heading1: "Growth Curves",
          //   image_heading1: "Growth Curves",
          //   username: userName,
          //   timeframe: "",
          //   start_date: "",
          //   end_date: "",
          //   titlename: "Growth Curves",
          //   category: "",
          //   chartdata: HeirarchyData,
          //   brand: Analyse.Brand,
          //   kpi: Analyse.KPI,
          //   tabname: "roc",
          // },
          document : payloadPDF,
        });
  
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    } else {
      setLoader(false);
      console.error("Chart containers not found");
    }
  };


//  For searching channels based in search query..................
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Filter channels based on the search query
    const filtered = ChannelAll?.filter(channel =>
      channel.key.toLowerCase().includes(query.toLowerCase())
    );

    setFilteredChannels(filtered);
  };
  
  

  return (
    <>
      {loader ? <Loader /> : null}
      {
        ShowPopup ? <PopUp setShowPopup={setShowPopup} height="70%">
          {JSON.stringify(AdstockData) !== '{}' ? (
            <LineChart
              ChartTitle={AdstockData.data.title}
              series={AdstockData.data.series}
              xunits={AdstockData.data.xunits}
              plotBands={AdstockData.data.plotBands}
              labels={AdstockData.data.labels}
              yunits={AdstockData.data.yunits}
              SelectedChannel={''}
              height={'h-100-per'}
              setDownloadchart={setDownloadchart}
              Downloadchart={Downloadchart}
              Brand={Analyse.brand}
              ActiveFilters={Sortable}
              Fixed={Fixed}
              setLoader={setLoader}
            />
          ) : null}
        </PopUp> : null
      }
      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />
      <div className="main-content">
        <div className="page-content" >
          <div className="container-fluid main-content-w pt-0">
            <div className="row align-items-center" ref={RowWidthRef}>
              <div className="col-sm-2">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {/* Channel Efficacy ROC */}
                    {
                      (Analyse && Analyse.Menubar) ?
                        Analyse.Menubar.map((item) => {
                          return ('/' + item.key === location.pathname) ? item.value : null
                        })
                        : null
                    }
                  </h1>
                </div>
              </div>
              <div className="col-sm-12 mt-0" style={{ alignItems: "center" }}>
                <div className="card my-1">
                  <div className="card-body row allFilter pb-0 pt-0">
                    {
                      Fixed.length !== 0 ?
                        Fixed.map((item, i) => {
                          return (
                            <div className="col-sm-2 d-flex align-items-sm-center" key={i}>
                              <h6 className="m-0 px-2 custom-roc-filter-title">{item.id}</h6>
                              <select
                                className="form-select"
                                onChange={(e) => {
                                }}
                                value={item.value}
                              // disabled={true}
                              >
                                <option value={item.value} key={item.value}>{item.display}</option>
                              </select>
                            </div>
                          )
                        })
                        : null
                    }

                    {
                      Sortable.length !== 0 ?
                        Sortable.map((item, i) => {
                          // console.log("item: ", item.default_value);

                          return (
                            <div className="col-sm-2 d-flex align-items-sm-center" key={i}>
                              <h6 className="m-0 px-2 custom-roc-filter-title">{item.name}</h6>

                              <select
                                className="form-select"
                                onChange={(e) => {
                                  OnChangeHandler(i, e.target.value);
                                }}
                                value={item.default_value}
                              >
                                {
                                  item.options.map((data) => {
                                    return (
                                      <option value={data.value} key={data.value}>{data.label}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          )
                        })
                        : null
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div className="container-fluid main-content-w pt-1">
            <div className="row">
              <div className={parseInt(ShowSummary) === 0 && JSON.stringify(AdstockData) === '{}' ? "col-xl-12" : "col-xl-8"}>
                <div className="card card-min-height align-items-sm-center" ref={DivRef}>
                  <div className="row w-100">
                    <div className="col-sm-3 card-min-height py-3">
                      <h3 className="data-spends my-2" style={{ marginLeft: '20px' }}>Channels</h3>
                      {/* Search for channels start..... */}
                      <input
                        type="text"
                        className="form-control main-search custom-input-box"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={handleSearch}
                      />
                      {/* Search for channels end..... */}
                      <div className="d-flex flex-column channel-list mt-2">
                        {filteredChannels.map((item) => {
                          return (
                            <Link
                              key={item.key}
                              className={
                                SelectedChannel === item.key
                                  ? "channels-link active"
                                  : "channels-link"
                              }
                              onClick={(e) => {
                                // setROCData({});
                                e.preventDefault();
                                setSelectedChannel(item.key)
                                setFetchCall(true)
                              }}
                              to=""
                              role="tab"
                            >
                              {item.key}
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                    <div className="col-sm-9 brand-card ef-drivers card-min-height" style={{ borderBottomLeftRadius: "0px", borderTopLeftRadius: "0px", }}>
                      {JSON.stringify(ROCData) !== '{}' ? (<>
                        <div className="row align-items-center justify-content-sm-between pb-0">
                          <div className="col-sm-10 d-flex align-items-center justify-content-sm-end">
                            {
                              ChartData.length > 0 ?
                                // <ul className="p-0 m-0">
                                //   {
                                ChartData.map((item, index) => {
                                  return (
                                    // <li key={index} className="cm-filter-option-li cursor-pointer" onClick={() => setSelectedChartData(item)}>
                                    //   <span className={SelectedChartData === item ? "btn cm-drag-btn p-1 mx-2 active" : "btn cm-drag-btn p-1 mx-2"}>
                                    //     <p className="m-0 text-center px-1" style={{ width: '150px' }}>{item.tab_name}</p>
                                    //   </span>
                                    // </li>
                                    <p key={index} className={SelectedChartData === item ? "m-1 px-2 mx-2 cursor-pointer text-white lt-st custom-input-box" : "m-0 px-2 mx-2 cursor-pointer text-lable text-white custom-input-box"} onClick={() => {
                                      setSelectedChartData(item)
                                      setTabName(item.tab_name)
                                    }}>{item.tab_name}</p>
                                  )
                                })
                                //   }
                                // </ul> 
                                : null
                            }
                          </div>
                          <div className="col-sm-2 d-flex align-items-center justify-content-sm-end">
                            {
                              Notes !== "" ?
                                <>
                                  <p className="m-0 px-2">
                                    <svg src={InfoIcon} id="notes" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-info-circle" viewBox="0 0 16 16">
                                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                    </svg>
                                  </p>

                                  <Tooltip anchorSelect="#notes" clickable>
                                    {/* <div className="api-notes">
                                      {Notes}
                                    </div> */}
                                    <div
                                      className="api-notes"
                                      dangerouslySetInnerHTML={{ __html: Notes }}
                                    >
                                    </div>
                                  </Tooltip>
                                </> : null
                            }

                        { (downloadFlag.chart || downloadFlag.data || downloadFlag.pdf) && <span className="flex-shrink-0 dropdown">
                              <Link
                                to="#"
                                className="d-flex align-items-center text-decoration-none dropdown-toggle text-white"
                                id="dropdownUser2"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <p className="m-0 px-2">
                                  <svg src={Download} title="Download" xmlns="http://www.w3.org/2000/svg" width="25" height="25" class="bi bi-download" viewBox="0 0 16 16">
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                </p>
                              </Link>
                              <ul
                                className="dropdown-menu text-small shadow"
                                aria-labelledby="dropdownUser2"
                              >
                               {downloadFlag.chart && <li onClick={(e) => { }}>
                                  <Link className="dropdown-item" onClick={(e) => {
                                    e.preventDefault();
                                    if (ROCData.series.length > 0) {
                                      setChartContainerID("growth_curve_chart");
                                      setIsDownloadingChart(true);
                                      setDownloadedImageName("Growth Curves");
                                      setDownloadingChartTitle("Growth Curves")
                                      // setDownloadchart(true)
                                      // setLoader(true)
                                    } else {
                                      toast.error("Chartdata is not available")
                                    }
                                  }}>
                                    Download Chart
                                  </Link>
                                </li>}

                               {downloadFlag.data && <li onClick={(e) => { }}>
                                  <Link className="dropdown-item" onClick={(e) => DownloadData(e)} >Download Data</Link>
                                </li>}
                                {downloadFlag.pdf && <li onClick={(e) => { }}>
                                  <Link className="dropdown-item" onClick={(e) => downloadPDF(e)} >Download PDF</Link>
                                </li>}
                              </ul>
                            </span>}
                          </div>
                        </div>

                        <div className="growth-curve-chart-height" ref={refGraphDiv}>
                          {
                            ROCData.series.length > 0 ? <LineChart
                              ChartTitle={ROCData.title}
                              series={ROCData.series}
                              xunits={ROCData.xunits}
                              plotBands={ROCData.plotBands}
                              labels={ROCData.labels}
                              yunits={ROCData.yunits}
                              SelectedChannel={SelectedChannel}
                              Downloadchart={Downloadchart}
                              setDownloadchart={setDownloadchart}
                              setLoader={setLoader}
                              height={ChartHeight}
                              Brand={Analyse.brand}
                              ActiveFilters={Sortable}
                              Fixed={Fixed}
                            /> : null
                          }
                        </div>



                      </>) : null}
                    </div>
                  </div>

                </div>
              </div>

              {(JSON.stringify(AdstockData) !== '{}' || parseInt(ShowSummary) !== 0) ?

                <SummaryAdstock adstockChart={true} setIsDownloadingChart={setIsDownloadingChart} setChartContainerID={setChartContainerID} setDownloadedImageName={setDownloadedImageName} setDownloadingChartTitle={setDownloadingChartTitle} DivHeight={DivHeight} ShowSummary={ShowSummary} ChartHeight={refGraphDiv.current ? refGraphDiv.current.clientHeight + 12 : ''} Summary={Summary} AdstockData={AdstockData} setShowPopup={setShowPopup} /> : null
              }
            </div>

            <div className="row custom-note">
              <div className="col-sm-1">
                {
                  FooterNotes !== "" ?
                    <>
                      <i
                        className="fas fa-mail-bulk chart_info_icon footer-notes-icon"
                        id="clickable"
                      ></i>
                      <Tooltip anchorSelect="#clickable" clickable>
                        {/* <div className="footer-notes">
                          {FooterNotes}
                        </div> */}
                        <div
                          className="footer-notes"
                          dangerouslySetInnerHTML={{ __html: FooterNotes }}
                        >
                        </div>
                      </Tooltip>
                    </>
                    : null

                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
