import React from "react";
import { Link, useLocation } from "react-router-dom";
const Menubar = () => {
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));

  // console.log("Analyse: ", Analyse);
  
  return (
    <div>
      <div className="topnav p-0">
        <div className="container-fluid max-w-100 p-0">
          <div className="row w-100 m-0">
            {/* <div className="col-sm-1"></div> */}
            <div className="col-sm-12 sub-menu-bar">
              <nav className="navbar navbar-light navbar-expand-lg topnav-menu">
                <div
                  className="collapse navbar-collapse"
                  id="topnav-menu-content"
                >
                  <ul className="navbar-nav w-100 justify-content-sm-between">
                    {Analyse.Menubar.map((item) => {
                      return (
                        <li key={item.key} className="nav-item">
                          <Link
                            className={
                              location.pathname.split("/")[1] === item.key
                                ? "nav-link active underline"
                                : "nav-link"
                            }
                            to={"/" + (item.key === 'optimizer' ? 'optimize' : item.key)}
                          >
                            {item.value}
                          </Link>
                        </li>
                      );
                    })}

                    <li className="nav-item">
                      <Link
                        className={
                          location.pathname === "/savedresults"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        to={"/savedresults"}
                      >
                        Saved Results
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            {/* <div className="col-sm-1"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menubar;
