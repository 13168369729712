import { useEffect } from "react";

const WindowDimensions = () => {
  const updateWindowDimensions = () => {
    // window.location.reload();
    const allowedPages = ["/", "/login"];

    if (!allowedPages.includes(window.location.pathname)) {
      window.location.reload();
    }

  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);

    return () => {
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  return <></>;
};

export default WindowDimensions;
