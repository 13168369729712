import React, { useRef, useEffect, useState } from "react";
import MixChart from './MixChart'


const TrendChart = (props) => {
  const trendsRef = useRef(null);
  const [TrendsHeight, setTrendsHeight] = useState("")

  useEffect(() => {
    if (trendsRef.current !== null) {
        setTrendsHeight(trendsRef.current.clientHeight)
    }
   
  }, [props.TrendChartdata]);

    return (
        <div className="row mt-2">
            <div className="col-sm-12">
                {/* <p className="card-title-lable">&nbsp;</p> */}
                <div className="trends-chart-height" id="trends_chart" ref={trendsRef}>
                    <MixChart height={TrendsHeight} TrendChartRef={props.TrendChartRef} ChartData={props.TrendChartdata} Downloadchart={props.Downloadchart} setDownloadchart={props.setDownloadchart} />
                </div>
            </div>
        </div>
    )
}

export default TrendChart