import React, { useEffect, useState } from "react";
import Topbar from "../../Components/Topbar";
import Menubar from "../../Components/Menubar";
import axios from "../../axios";
import Loader from "../../Components/Loader";
import { useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Measurement from "./Measurement";
import Comparison from "./Comparison";
import ActiveHeirarchyAndCampaignFilter from "../ActiveHeirarchyAndCampaignFilter";  // for measurement
import AllHeirarchyFilter from "../AllHeirarchyFilter"; // H0, H1, etc
import AllCampaignFilter from "../AllCampaignFilter"; // C0, C1, ect
import CompActiveHeirarchyAndCampaignFilter from "../CompActiveHeirarchyAndCampaignFilter";  // for comparison
import TrendAnalysis from "./TrendAnalysis";

import LightThemeHexCode from "../LightThemeHexCode";
import useDownloadChart from "../../hooks/useDownloadChartC";

import axiosDownload from "../../axiosDownload";
import axiosChartDownload from "../../axiosChartDownload";
import domtoimage from "dom-to-image";


const Main = () => {
  const location = useLocation();
  const Analyse = JSON.parse(localStorage.getItem("Analyse"));
  // console.log("Analyse: ", Analyse);
  const [loader, setLoader] = useState(false);
  const [ActiveChart, setActiveChart] = useState("Measurement");
  // console.log("ActiveChart: ", ActiveChart);

  const [NotAllow, setNotAllow] = useState([]);
  // console.log("NotAllow: ", NotAllow);

  // measurement
  const [TimeFramesAll, setTimeFramesAll] = useState([]);
  const [FromDatesAll, setFromDatesAll] = useState([]);
  const [ToDatesAll, setToDatesAll] = useState([]);
  const [timeframe, setTimeframe] = useState("");
  const [DefaultStartDate, setDefaultStartDate] = useState("");
  const [DefaultEndDate, setDefaultEndDate] = useState("");

  
  // trends chart
  const [TimeFramesAllTrends, setTimeFramesAllTrends] = useState([]);
  const [FromDatesAllTrends, setFromDatesAllTrends] = useState([]);
  const [ToDatesAllTrends, setToDatesAllTrends] = useState([]);
  const [timeframeTrends, setTimeframeTrends] = useState("");
  const [DefaultStartDateTrends, setDefaultStartDateTrends] = useState("");
  const [DefaultEndDateTrends, setDefaultEndDateTrends] = useState("");
  const [showLegend, setShowLegend] = useState(false);

  // console.log("DefaultStartDateTrends: ", DefaultStartDateTrends, DefaultEndDateTrends);


  const [TimeFramesAllComp1, setTimeFramesAllComp1] = useState([]);
  const [FromDatesAllComp1, setFromDatesAllComp1] = useState([]);
  const [ToDatesAllComp1, setToDatesAllComp1] = useState([]);
  const [timeframeComp1, setTimeframeComp1] = useState("");
  const [DefaultStartDateComp1, setDefaultStartDateComp1] = useState("");
  const [DefaultEndDateComp1, setDefaultEndDateComp1] = useState("");

  const [TimeFramesAllComp2, setTimeFramesAllComp2] = useState([]);
  const [FromDatesAllComp2, setFromDatesAllComp2] = useState([]);
  const [ToDatesAllComp2, setToDatesAllComp2] = useState([]);
  const [timeframeComp2, setTimeframeComp2] = useState("");
  const [DefaultStartDateComp2, setDefaultStartDateComp2] = useState("");
  const [DefaultEndDateComp2, setDefaultEndDateComp2] = useState("");

  const [ZoomIn, setZoomIn] = useState(false);

  //Analytical filters state
  const AnalyticFilter = JSON.parse(localStorage.getItem("AnalyticFilter")); // H1, H2, etc.....
  // console.log("AnalyticFilter: ", AnalyticFilter); //h1, h2, h3, filter etc
  const CampaignAnalyticFilter = JSON.parse(localStorage.getItem("CampaignAnalyticFilter"));
  // console.log("CampaignAnalyticFilter: ", CampaignAnalyticFilter);
  const [AnalyticFilterFlag, setAnalyticFilterFlag] = useState(true);

  const [FilterTabShow, setFilterTabShow] = useState(false);
  const Fixed = localStorage.getItem("AnalyticFilter") ? AnalyticFilter.Fixed : [];
  // console.log("Fixed: ", Fixed);

  //Measurement Chart 
  const [AllFilters, setAllFilters] = useState([]);
  // console.log("AllFilters: ", AllFilters);
  const [ActiveFilters, setActiveFilters] = useState([]);
  // console.log("ActiveFilters: ", ActiveFilters);

  const [ClickedCategory, setClickedCategory] = useState(null);
  // console.log("ClickedCategory: ", ClickedCategory);
  const [Chartdata, setChartdata] = useState({});
  // console.log("Chartdata: ", Chartdata);
  const [BreadCrumbs, setBreadCrumbs] = useState([]);
  // console.log("BreadCrumbs: ", BreadCrumbs);
  const [categories, setCategories] = useState([]);
  // console.log("categories: ", categories);
  const [series, setSeries] = useState([]);
  const [Summary, setSummary] = useState([]);
  const [FetchCall, setFetchCall] = useState(true);
  // const [FetchCallTrend, setFetchCallTrend] = useState(true);
  const [IsAllEnabled, setIsAllEnabled] = useState(0);
  // console.log("IsAllEnabled: ", IsAllEnabled);
  const [Reset, setReset] = useState(0);
  const [charttitle, setcharttitle] = useState("");
  const [xtitle, setxtitle] = useState("");
  const [ytitle, setytitle] = useState("");

  // trends chart
  const [BreadCrumbsTrends, setBreadCrumbsTrends] = useState([]);
  // console.log("BreadCrumbsTrends: ", BreadCrumbsTrends);
  const [categoriesTrends, setCategoriesTrends] = useState([]);
  const [seriesTrends, setSeriesTrends] = useState([]);
  const [FetchCallTrends, setFetchCallTrends] = useState(true);
  const [charttitleTrends, setcharttitleTrends] = useState("");
  const [xtitleTrends, setxtitleTrends] = useState("");
  const [ytitleTrends, setytitleTrends] = useState("");
  const [ChartdataTrends, setChartdataTrends] = useState({});


  //Comparison Chart 1
  const [BreadCrumbsComp1, setBreadCrumbsComp1] = useState([]);
  // console.log("BreadCrumbsComp1: ", BreadCrumbsComp1);
  const [categoriesComp1, setCategoriesComp1] = useState([]);
  const [seriesComp1, setSeriesComp1] = useState([]);
  const [FetchCallComp1, setFetchCallComp1] = useState(true);
  const [charttitleComp1, setcharttitleComp1] = useState("");
  const [xtitleComp1, setxtitleComp1] = useState("");
  const [ytitleComp1, setytitleComp1] = useState("");
  const [ChartdataComp1, setChartdataComp1] = useState({});
  // console.log("ChartdataComp1: ", ChartdataComp1);
  const [ClickedCategoryComp1, setClickedCategoryComp1] = useState(null);

  //Comparison Chart 2
  const [BreadCrumbsComp2, setBreadCrumbsComp2] = useState([]);
  const [categoriesComp2, setCategoriesComp2] = useState([]);
  const [seriesComp2, setSeriesComp2] = useState([]);
  const [FetchCallComp2, setFetchCallComp2] = useState(true);
  const [charttitleComp2, setcharttitleComp2] = useState("");
  const [xtitleComp2, setxtitleComp2] = useState("");
  const [ytitleComp2, setytitleComp2] = useState("");
  const [ChartdataComp2, setChartdataComp2] = useState({});
  // console.log("ChartdataComp2: ", ChartdataComp2);
  const [ClickedCategoryComp2, setClickedCategoryComp2] = useState(null);

  //Comp filters state
  const [CompAllFilters, setCompAllFilters] = useState([]);
  const [CompActiveFilters, setCompActiveFilters] = useState([]);
  // console.log("CompActiveFilters: ", CompActiveFilters);

  // const [Downloadchart, setDownloadchart] = useState(false)
  const [ShowSummary, setShowSummary] = useState(0)
  const [FooterNotes, setFooterNotes] = useState("");
  const [MResetFilterFlag, setMResetFilterFlag] = useState(false)
  const [CResetFilterFlag, setCResetFilterFlag] = useState(false)



  const [isDownloadingChart, setIsDownloadingChart] = useState(false);
  const [chartContainerID, setChartContainerID] = useState("");
  const [downloadedImageName, setDownloadedImageName] = useState("");
  const [downloadingChartTitle, setDownloadingChartTitle] = useState("");
  const [Heirarchy, setHierarchy] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [timeFrameChart, setTimeFrameChart] = useState("");

  const userName = localStorage.getItem("NameSkewb");
  const [IsAllEnabledDownload, setIsAllEnabledDownload] = useState(0);
  const [IsAllEnabledDownloadC2, setIsAllEnabledDownloadC2] = useState(0);


  // console.log("IsAllEnabledDownload: ", IsAllEnabledDownload);
  // console.log("IsAllEnabledDownloadC2: ", IsAllEnabledDownloadC2);


  const ChartdataForHierarchyFilters = () => {
    if (ActiveChart === "Measurement") {
      return [Chartdata];
    } else if (ActiveChart === "Comparison") {
      return [ChartdataComp1, ChartdataComp2];
    } else {
      return [ChartdataTrends];
    }
  };

  useEffect(() => {
    setIsAllEnabledDownload(0);
    setIsAllEnabledDownloadC2(0);
  }, [ActiveChart])


  useEffect(() => {
    setFetchCall(true);
    setFetchCallComp1(true);
    setFetchCallComp2(true);
    setFetchCallTrends(true);
  }, [Analyse.Brand, Analyse.KPI]);



  useDownloadChart(
    downloadedImageName,
    downloadingChartTitle,
    isDownloadingChart,
    chartContainerID,
    Heirarchy,
    setLoader,
    Analyse.Brand,
    timeFrameChart,
    startDate,
    endDate,
    setIsDownloadingChart,
    Analyse.KPI
  );


  useEffect(() => {
    if (AnalyticFilterFlag && AnalyticFilter && CampaignAnalyticFilter) {
      setAllFilters(AnalyticFilter.AllFilters)
      setActiveFilters(AnalyticFilter.ActiveFilters)
      setCompActiveFilters(CampaignAnalyticFilter.CompActiveFilters);
      setCompAllFilters(CampaignAnalyticFilter.CompAllFilters)
      setAnalyticFilterFlag(false)
    }
  }, [AnalyticFilter, CampaignAnalyticFilter, AnalyticFilterFlag])

  const onChangeHandler = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAll.length; i++) {
      if (TimeFramesAll[i].key === value) {
        setFromDatesAll(TimeFramesAll[i].fromdates);
        setToDatesAll(TimeFramesAll[i].todates);
        setDefaultStartDate(TimeFramesAll[i].default_startdate); // added this line by Naeem
        setDefaultEndDate(TimeFramesAll[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframe(value);
  };

  const onChangeHandlerComp1 = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAllComp1.length; i++) {
      if (TimeFramesAllComp1[i].key === value) {
        setFromDatesAllComp1(TimeFramesAllComp1[i].fromdates);
        setToDatesAllComp1(TimeFramesAllComp1[i].todates);
        setDefaultStartDateComp1(TimeFramesAllComp1[i].default_startdate); // added this line by Naeem
        setDefaultEndDateComp1(TimeFramesAllComp1[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframeComp1(value);
  };

  const onChangeHandlerComp2 = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAllComp2.length; i++) {
      if (TimeFramesAllComp2[i].key === value) {
        setFromDatesAllComp2(TimeFramesAllComp2[i].fromdates);
        setToDatesAllComp2(TimeFramesAllComp2[i].todates);
        setDefaultStartDateComp2(TimeFramesAllComp2[i].default_startdate); // added this line by Naeem
        setDefaultEndDateComp2(TimeFramesAllComp2[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframeComp2(value);
  };

  const onChangeHandlerTrends = (e) => {
    const value = e.target.value;
    for (var i = 0; i < TimeFramesAllTrends.length; i++) {
      if (TimeFramesAllTrends[i].key === value) {
        setFromDatesAllTrends(TimeFramesAllTrends[i].fromdates);
        setToDatesAllTrends(TimeFramesAllTrends[i].todates);
        setDefaultStartDateTrends(TimeFramesAllTrends[i].default_startdate); // added this line by Naeem
        setDefaultEndDateTrends(TimeFramesAllTrends[i].default_enddate); // added this line by Naeem
      }
    }
    setTimeframeTrends(value);
  };

  useEffect(() => {
    setLoader(true);
    axios
      .post("get_timeframes/", { page: "grain_contri", brand: Analyse.Brand, kpi: Analyse.KPI })
      .then((response) => {
        if (response.data.error === 1) {
          toast.error(response.data.erroMsg);
          setLoader(false);
        } else {
          setTimeFramesAll(response.data.data);
          setTimeframe(response.data.data[0].key);
          setFromDatesAll(response.data.data[0].fromdates);
          setToDatesAll(response.data.data[0].todates);
          setDefaultStartDate(response.data.data[0].default_startdate);
          setDefaultEndDate(response.data.data[0].default_enddate);

          setTimeFramesAllComp1(response.data.data);
          setTimeframeComp1(response.data.data[0].key);
          setFromDatesAllComp1(response.data.data[0].fromdates);
          setToDatesAllComp1(response.data.data[0].todates);
          setDefaultStartDateComp1(response.data.data[0].default_startdate);
          setDefaultEndDateComp1(response.data.data[0].default_enddate);

          setTimeFramesAllComp2(response.data.data);
          setTimeframeComp2(response.data.data[0].key);
          setFromDatesAllComp2(response.data.data[0].fromdates);
          setToDatesAllComp2(response.data.data[0].todates);
          setDefaultStartDateComp2(response.data.data[0].default_startdate);
          setDefaultEndDateComp2(response.data.data[0].default_enddate);

          setTimeFramesAllTrends(response.data.data);
          setTimeframeTrends(response.data.data[0].key);
          setFromDatesAllTrends(response.data.data[0].fromdates);
          setToDatesAllTrends(response.data.data[0].todates);
          setDefaultStartDateTrends(response.data.data[0].default_startdate);
          setDefaultEndDateTrends(response.data.data[0].default_enddate);


          setLoader(false);
        }
      })
      .catch((data) => {
        setLoader(false);
      });
  }, [Analyse.Brand, Analyse.KPI]);

  useEffect(() => {
    if (FetchCall && ActiveChart === "Measurement") {
      if (
        timeframe !== "" &&
        DefaultStartDate !== "" &&
        DefaultEndDate !== "" &&
        JSON.stringify(Chartdata) !== "{}"
      ) {
        if (
          FromDatesAll.indexOf(DefaultStartDate) >
          ToDatesAll.indexOf(DefaultEndDate)
        ) {
          toast.error("Please enter valid time period");
          setFetchCall(false);
        } else {
          setLoader(true);
          setCategories([]);
          setSeries([]);
          axios
            .post("get_grain_contrib_new/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframe,
              startdate: DefaultStartDate,
              enddate: DefaultEndDate,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
              chartdata: Chartdata,
              theme: localStorage.getItem("CurrentTheme"),
              LightThemeColorCode: [localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],


            })
            .then((response) => {
              if (response.data.error === 1) {
                // toast.error(response.data.erroMsg);
                toast.error("No data found for the following filter, please reset the filter");
                setLoader(false);
              } else {
                setNotAllow(response.data.data.nonclicklist);
                setCategories(response.data.data.categories);
                setSeries(response.data.data.series);
                setSummary(response.data.data.summary || []);
                setFooterNotes(response.data.notes);
                setcharttitle(response.data.data.charttitle);
                setxtitle(response.data.data.xtitle);
                setytitle(response.data.data.ytitle);
                if (response.data.data.show_summary !== undefined) {
                  setShowSummary(response.data.data.show_summary)
                }
                setLoader(false);
              }
              setIsAllEnabled(0);
              setReset(0);
              setFetchCall(false);
            })
            .catch((data) => {
              setLoader(false);
            });
        }
      }
    }
  }, [
    Analyse.Brand,
    Analyse.KPI,
    timeframe,
    DefaultStartDate,
    DefaultEndDate,
    FetchCall,
    FromDatesAll,
    ToDatesAll,
    Chartdata,
    ActiveFilters,
    ActiveChart,
    IsAllEnabled,
    Reset,
  ]);

  useEffect(() => {
    if (FetchCallComp1 && ActiveChart === "Comparison") {
      if (
        timeframeComp1 !== "" &&
        DefaultStartDateComp1 !== "" &&
        DefaultEndDateComp1 !== "" &&
        JSON.stringify(ChartdataComp1) !== "{}"
      ) {
        if (
          FromDatesAllComp1.indexOf(DefaultStartDateComp1) >
          ToDatesAllComp1.indexOf(DefaultEndDateComp1)
        ) {
          toast.error("Please enter valid time period");
          setFetchCall(false);
        } else {
          setLoader(true);
          setCategoriesComp1([]);
          setSeriesComp1([]);
          axios
            .post("get_grain_contrib_new/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframeComp1,
              startdate: DefaultStartDateComp1,
              enddate: DefaultEndDateComp1,
              chartdata: ChartdataComp1,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
              chart: "comp-1",
              theme: localStorage.getItem("CurrentTheme"),
              LightThemeColorCode: [localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],


            })
            .then((response) => {
              if (response.data.error === 1) {
                // toast.error(response.data.erroMsg);
                toast.error("No data found for the following filter, please reset the filter");
                setLoader(false);
              } else {
                // setCategories(get_grain.data.categories);
                // setSeries(get_grain.data.series);
                setNotAllow(response.data.data.nonclicklist);
                setCategoriesComp1(response.data.data.categories);
                setSeriesComp1(response.data.data.series);
                // setSummary(response.data.data.summary || []);
                setFooterNotes(response.data.notes);
                setcharttitleComp1(response.data.data.charttitle);
                setxtitleComp1(response.data.data.xtitle);
                setytitleComp1(response.data.data.ytitle);
                setLoader(false);
              }
              setIsAllEnabled(0);
              setReset(0);
              setFetchCallComp1(false);
            })
            .catch((data) => {
              setLoader(false);
            });
        }
      }
    }
  }, [
    Analyse.Brand,
    Analyse.KPI,
    timeframeComp1,
    DefaultStartDateComp1,
    DefaultEndDateComp1,
    FetchCallComp1,
    FromDatesAllComp1,
    ToDatesAllComp1,
    ChartdataComp1,
    ActiveChart,
    IsAllEnabled,
    Reset,
  ]);

  useEffect(() => {
    if (FetchCallComp2 && ActiveChart === "Comparison") {
      if (
        timeframeComp2 !== "" &&
        DefaultStartDateComp2 !== "" &&
        DefaultEndDateComp2 !== "" &&
        JSON.stringify(ChartdataComp2) !== "{}"
      ) {

        if (
          FromDatesAllComp2.indexOf(DefaultStartDateComp2) >
          ToDatesAllComp2.indexOf(DefaultEndDateComp2)
        ) {
          toast.error("Please enter valid time period");
          setFetchCall(false);
        } else {
          setLoader(true);
          setCategoriesComp2([]);
          setSeriesComp2([]);
          axios
            .post("get_grain_contrib_new/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframeComp2,
              startdate: DefaultStartDateComp2,
              enddate: DefaultEndDateComp2,
              chartdata: ChartdataComp2,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
              chart: "comp-2",
              theme: localStorage.getItem("CurrentTheme"),
              LightThemeColorCode: [localStorage.getItem("ColorCode"), LightThemeHexCode[localStorage.getItem("ColorCode")]],

            })
            .then((response) => {
              if (response.data.error === 1) {
                // toast.error(response.data.erroMsg);
                toast.error("No data found for the following filter, please reset the filter");
                setLoader(false);
              } else {
                setNotAllow(response.data.data.nonclicklist);
                setCategoriesComp2(response.data.data.categories);
                setSeriesComp2(response.data.data.series);
                // setSummary(response.data.data.summary || []);
                setFooterNotes(response.data.notes);
                setcharttitleComp2(response.data.data.charttitle);
                setxtitleComp2(response.data.data.xtitle);
                setytitleComp2(response.data.data.ytitle);
                setLoader(false);
              }
              setIsAllEnabled(0);
              setReset(0);
              setFetchCallComp2(false);
            })
            .catch((data) => {
              setLoader(false);
            });
        }
      }
    }
  }, [
    Analyse.Brand,
    Analyse.KPI,
    timeframeComp2,
    DefaultStartDateComp2,
    DefaultEndDateComp2,
    FetchCallComp2,
    FromDatesAllComp2,
    ToDatesAllComp2,
    ChartdataComp2,
    ActiveChart,
    IsAllEnabled,
    Reset,
  ]);

  

  // for trend analysis
  // "get_contributions_stack/"
  // const URL = "http://15.207.123.147:8000/chartdata/"
  useEffect(() => {
    if (FetchCallTrends && ActiveChart === "Trend_Analysis") {
      if (
        timeframeTrends !== "" &&
        DefaultStartDateTrends !== "" &&
        DefaultEndDateTrends !== "" &&
        JSON.stringify(ChartdataTrends) !== "{}"
      ) {
        if (
          FromDatesAllTrends.indexOf(DefaultStartDateTrends) >
          ToDatesAllTrends.indexOf(DefaultEndDateTrends)
        ) {
          toast.error("Please enter valid time period");
          setFetchCallTrends(false);
        } else {
          setLoader(true);
          setCategoriesTrends([]);
          setSeriesTrends([]);
          axios
            .post("get_contributions_stack/", {
              brand: Analyse.Brand,
              kpi: Analyse.KPI,
              timeframe: timeframeTrends,
              startdate: DefaultStartDateTrends,
              enddate: DefaultEndDateTrends,
              is_all_enabled: IsAllEnabled,
              reset: Reset,
              chartdata: ChartdataTrends,
              theme: localStorage.getItem("CurrentTheme"),
            })
            .then((response) => {
              // console.log("Trends Chart Data: ", response.data);
              if (response.data.error === 1) {
                // toast.error(response.data.data.erroMsg);
                toast.error("No data found for the following filter, please reset the filter");
                setLoader(false);
              } else {
                setNotAllow(response.data.data.nonclicklist);
                setCategoriesTrends(response.data.data.categories);
                setSeriesTrends(response.data.data.series);
                setSummary(response.data.data.summary || []);
                setFooterNotes(response.data.data.notes);
                setcharttitleTrends(response.data.data.charttitle);
                setxtitleTrends(response.data.data.xtitle);
                setytitleTrends(response.data.data.ytitle);
                setShowLegend(response.data.data.show_legend);
                if (response.data.data.show_summary !== undefined) {
                  setShowSummary(response.data.data.show_summary)
                }
                setLoader(false);
              }
              setIsAllEnabled(0);
              setReset(0);
              setFetchCallTrends(false);
            })
            .catch((data) => {
              setLoader(false);
            });
        }
      }
    }
  }, [
    Analyse.Brand,
    Analyse.KPI,
    timeframeTrends,
    DefaultStartDateTrends,
    DefaultEndDateTrends,
    FetchCallTrends,
    FromDatesAllTrends,
    ToDatesAllTrends,
    ChartdataTrends,
    ActiveFilters,
    ActiveChart,
    IsAllEnabled,
    Reset,
  ]);



  

  // When Activefilter change : prepare chartdata for input of get api call
  
  
  useEffect(() => {
    const chartdata = {};
    const chartdata1 = {};
    const chartdata2 = {};
    const chartdata3 = {};

    const ChartdataTemp = structuredClone(Chartdata);
    const ChartdataTemp1 = structuredClone(ChartdataComp1);
    const ChartdataTemp2 = structuredClone(ChartdataComp2);
    const ChartdataTemp3 = structuredClone(ChartdataTrends);


    if (Fixed.length !== 0) {
      for (var r = 0; r < Fixed.length; r++) {
        const Filter = [];
        Filter.push(Fixed[r].value)
        chartdata[Fixed[r].id] = {
          selection: [],
          Filter: Filter,
        };

        chartdata1[Fixed[r].id] = {
          selection: [],
          Filter: Filter,
        };

        chartdata2[Fixed[r].id] = {
          selection: [],
          Filter: Filter,
        };

        chartdata3[Fixed[r].id] = {
          selection: [],
          Filter: Filter,
        };

      }
    }

    if (ActiveFilters.length !== 0) {
      for (var i = 0; i < ActiveFilters.length; i++) {
        const selection = [];
        const selection1 = [];
        const selection2 = [];
        const selection3 = [];

        if ("Selected" in ActiveFilters[i]) {
          if (ActiveFilters[i]["Selected"].length > 0) {
            setMResetFilterFlag(true)
          }
          for (var j = 0; j < ActiveFilters[i].Selected.length; j++) {
            var temp_arr = ActiveFilters[i].Selected[j].value.split("-");
            selection.push(temp_arr[temp_arr.length - 1]);
          }
        }

        if ("Selected1" in ActiveFilters[i]) {
          if (ActiveFilters[i]["Selected1"].length > 0) {
            setCResetFilterFlag(true)
          }
          for (var x = 0; x < ActiveFilters[i].Selected1.length; x++) {
            var temp_arr1 = ActiveFilters[i].Selected1[x].value.split("-");
            selection1.push(temp_arr1[temp_arr1.length - 1]);
          }
        }

        if ("Selected2" in ActiveFilters[i]) {
          if (ActiveFilters[i]["Selected2"].length > 0) {
            setCResetFilterFlag(true)
          }
          for (var y = 0; y < ActiveFilters[i].Selected2.length; y++) {
            var temp_arr2 = ActiveFilters[i].Selected2[y].value.split("-");
            selection2.push(temp_arr2[temp_arr2.length - 1]);
          }
        }


        if ("Selected" in ActiveFilters[i]) {
          if (ActiveFilters[i]["Selected"].length > 0) {
            setMResetFilterFlag(true)
          }
          for (var m = 0; m < ActiveFilters[i].Selected.length; m++) {
            var temp_arr3 = ActiveFilters[m].Selected[m].value.split("-");
            selection3.push(temp_arr3[temp_arr3.length - 1]);
          }
        }

       

        chartdata[ActiveFilters[i].name] = {
          selection: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].selection : [],
          Filter: selection,
        };

        chartdata1[ActiveFilters[i].name] = {
          selection: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].selection : [],
          Filter: selection1,
        };

        chartdata2[ActiveFilters[i].name] = {
          selection: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].selection : [],
          Filter: selection2,
        };

        chartdata3[ActiveFilters[i].name] = {
          selection: typeof Chartdata[ActiveFilters[i].name] !== 'undefined' ? Chartdata[ActiveFilters[i].name].selection : [],
          Filter: selection3,
        };

       
      }
    }

    for (var k = 0; k < CompActiveFilters.length; k++) {
      const selection = [];
      const selection1 = [];
      const selection2 = [];
      const selection3 = [];
      if ("Selected" in CompActiveFilters[k]) {
        if (CompActiveFilters[k]["Selected"].length > 0) {
          setMResetFilterFlag(true)
        }
        for (var a = 0; a < CompActiveFilters[k].Selected.length; a++) {
          selection.push(CompActiveFilters[k].Selected[a].value);
        }
      }
      chartdata[CompActiveFilters[k].name] = {
        selection: [],
        Filter: selection,
      };

      if ("Selected1" in CompActiveFilters[k]) {
        if (CompActiveFilters[k]["Selected1"].length > 0) {
          setCResetFilterFlag(true)
        }
        for (var b = 0; b < CompActiveFilters[k].Selected1.length; b++) {
          selection1.push(CompActiveFilters[k].Selected1[b].value);
        }
      }
      chartdata1[CompActiveFilters[k].name] = {
        selection: [],
        Filter: selection1,
      };

      if ("Selected2" in CompActiveFilters[k]) {
        if (CompActiveFilters[k]["Selected2"].length > 0) {
          setCResetFilterFlag(true)
        }
        for (var c = 0; c < CompActiveFilters[k].Selected2.length; c++) {
          selection2.push(CompActiveFilters[k].Selected2[c].value);
        }
      }
      chartdata2[CompActiveFilters[k].name] = {
        selection: [],
        Filter: selection2,
      };


      if ("Selected" in CompActiveFilters[k]) {
        if (CompActiveFilters[k]["Selected"].length > 0) {
          setMResetFilterFlag(true)
        }
        for (var d = 0; d < CompActiveFilters[k].Selected.length; d++) {
          selection3.push(CompActiveFilters[k].Selected[d].value);
        }
      }
      chartdata3[CompActiveFilters[k].name] = {
        selection: [],
        Filter: selection3,
      };
      
    
    }


    const keys = Object.keys(chartdata);
    const lastKeyIndex = keys.length - 1;
    
    for (let i = 0; i < lastKeyIndex; i++) {
      const key = keys[i];
      if (ChartdataTemp.hasOwnProperty(key)) {
        chartdata[key].selection = ChartdataTemp[key].selection;
      }
    }

    const keys1 = Object.keys(chartdata1);
    const lastKeyIndex1 = keys1.length - 1;
    
    for (let i = 0; i < lastKeyIndex1; i++) {
      const key = keys1[i];
      if (ChartdataTemp1.hasOwnProperty(key)) {
        chartdata1[key].selection = ChartdataTemp1[key].selection;
      }
    }

    const keys2 = Object.keys(chartdata2);
    const lastKeyIndex2 = keys2.length - 1;
    
    for (let i = 0; i < lastKeyIndex2; i++) {
      const key = keys2[i];
      if (ChartdataTemp2.hasOwnProperty(key)) {
        chartdata2[key].selection = ChartdataTemp2[key].selection;
      }
    }

    const keys3 = Object.keys(chartdata3);
    const lastKeyIndex3 = keys3.length - 1;
    
    for (let i = 0; i < lastKeyIndex3; i++) {
      const key = keys3[i];
      if (ChartdataTemp3.hasOwnProperty(key)) {
        chartdata3[key].selection = ChartdataTemp3[key].selection;
      }
    }

    if (ActiveChart === "Comparison") {
      setChartdataComp1(chartdata1)
      setFetchCallComp1(true)

      setChartdataComp2(chartdata2)
      setFetchCallComp2(true)

    } else if(ActiveChart === "Trend_Analysis") {
      setChartdataTrends(chartdata3);
      setFetchCallTrends(true);
    }
      else {
      setChartdata(chartdata);
      setFetchCall(true);
    }
  }, [ActiveFilters, CompActiveFilters, ActiveChart]);

  //HEIRARCHY FILTERS 
  const GetOption = (current_level, selection, destination_index) => {
    setLoader(true);
    axios
      .post("get_hirarchy_dropdown_options/", {
        hirarchy: Analyse.Brand,
        current_level: current_level,
        old_selection: selection !== "all" ? selection : ""
      })
      .then((response) => {
        const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
        var sort_arr = [...AllFilters];
        for (var i = 0; i < sort_arr.length; i++) {
          if (parseInt(sort_arr[i].level) === current_level) {
            var obj = sort_arr[i];
            obj['filters'] = response.data.data.options;
            obj['Selected'] = [];

            const temp_index = sort_arr.indexOf(sort_arr[i]);
            if (temp_index > -1) { // only splice array when item is found
              sort_arr.splice(temp_index, 1); // 2nd parameter means remove one item only
            }

            ActiveFiltersTemp.splice(
              destination_index,
              0,
              obj
            );
          }
        }
        let data = [...ActiveFiltersTemp];
        for (let k = 0; k < ActiveFiltersTemp.length; k++) {
          if (destination_index < k) {
            data[k]['filters'] = [];
            data[k]['Selected'] = [];
          }
        }
        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data
        };

        localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

        setLoader(false);
      }).catch((data) => {
        setLoader(false);
      });
  }

  const AddHeirarchyFilter = (index) => {

    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    let selection_value = [];
    var i = ((ActiveFiltersCompTemp.length) - 1);
    while (i >= 0) {
      if (ActiveFilters[i]['Selected'].length !== 0) {
        for (var j = 0; j < ActiveFilters[i]['Selected'].length; j++) {
          selection_value.push(ActiveFilters[i]['Selected'][j].value === "all" ? "" : ActiveFilters[i]['Selected'][j].value);
        }
      }
      i--;
    }

    const selection = selection_value.toString().replaceAll(",", ";");
    GetOption(parseInt(AllFilters[index].level), selection, ActiveFiltersCompTemp.length)

    let filter = AllFilters[index];
    filter.Selected = [];

    // setActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);
  };

  const onChangeHeirarchyFilter = (selected, index, key, data) => {
    const ActiveFiltersTemp = data.length > 0 ? data : JSON.parse(JSON.stringify(ActiveFilters));

    ActiveFiltersTemp[index].Selected = [];
    if (key === "") {
      ActiveFiltersTemp[index].Selected = selected;
    } else if (key === "Selected") {
      ActiveFiltersTemp[index]["Selected"] = selected;
    } else if (key === "Selected1") {
      ActiveFiltersTemp[index]["Selected1"] = selected;
    } else if (key === "Selected2") {
      ActiveFiltersTemp[index]["Selected2"] = selected;
    } 

    let next_level_arr = [];
    for (var j = 0; j < ActiveFiltersTemp.length; j++) {
      if (j > index) {
        next_level_arr.push(ActiveFiltersTemp[j].level);
      }
    }

    const next_level = next_level_arr.toString().replaceAll(",", ";");

    setLoader(true);

    var selection_value = [];
    for (var i = 0; i < selected.length; i++) {
      selection_value.push(selected[i].value);
    }

    const selection = selection_value.toString().replaceAll(",", ";");

    axios
      .post("hirarchy_dropdown_child_options/", {
        hirarchy: Analyse.Brand,
        "selection": selection !== "all" ? selection : "",
        "next_level": next_level
      })

      .then((response) => {
        var data = [...ActiveFiltersTemp]
        for (var i = 0; i < ActiveFiltersTemp.length; i++) {

          if (response.data.data[data[i].level]) {

            if (key === "") {
              data[i]['Selected'] = []
            } else {
              data[i][key] = []
            }
            data[i]['filters'] = response.data.data[data[i].level];
          }
        }

        setActiveFilters(data);

        //Set analytical filters data in localstorage
        const AnalyticFilterTemp = {
          AllFilters: AllFilters,
          Fixed: Fixed,
          ActiveFilters: data
        };

        localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

        setLoader(false);
      }).catch((data) => {
        setLoader(false);
      });
  };

  const onHeirarchyDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "inactive"
    ) {

      let selection_value = [];

      var i = ((result.destination.index) - 1);
      while (i >= 0) {
        if (ActiveFilters[i]['Selected'].length !== 0) {
          for (var j = 0; j < ActiveFilters[i]['Selected'].length; j++) {
            selection_value.push(ActiveFilters[i]['Selected'][j].value === "all" ? "" : ActiveFilters[i]['Selected'][j].value);
          }
        }
        i--;
      }
      const selection = selection_value.toString().replaceAll(",", ";");
      GetOption(parseInt(result.draggableId), selection, result.destination.index)
    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "active"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);

      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      ActiveFiltersTemp.splice(result.source.index, 1);
      setActiveFilters(ActiveFiltersTemp);
    }

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersTemp = JSON.parse(JSON.stringify(ActiveFilters));
      console.log(ActiveFiltersTemp, "ActiveFiltersTemp")
      ActiveFiltersTemp.splice(result.source.index, 1);
      ActiveFiltersTemp.splice(
        result.destination.index,
        0,
        ActiveFilters[result.source.index]
      );
      onChangeHeirarchyFilter(ActiveFiltersTemp[result.destination.index].Selected, result.destination.index, "", ActiveFiltersTemp);

    }

    if (
      result.destination.droppableId === "inactive" &&
      result.source.droppableId === "inactive"
    ) {
      const AllFiltersTemp = JSON.parse(JSON.stringify(AllFilters));
      AllFiltersTemp.splice(result.source.index, 1);
      AllFiltersTemp.splice(
        result.destination.index,
        0,
        AllFilters[result.source.index]
      );
      setAllFilters(AllFiltersTemp);
    }

  };

  const RemoveHeirarchyFilter = (item) => {

    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(ActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

  };

  const IsInAllHeirarchyFilters = (item) => {
    for (let i = 0; i < ActiveFilters.length; i++) {
      if (ActiveFilters[i].name === item.name) {
        return true
      }
    }
    return false
  }

  //CHANNEL FILTERS
  const AddChannelFilter = (index) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    let filter = CompAllFilters[index];
    filter.Selected = [filter.filters[0]];

    ActiveFiltersCompTemp.push(filter);
    setCompActiveFilters(ActiveFiltersCompTemp);
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
  };

  const RemoveChannelFilter = (item) => {
    const ActiveFiltersCompTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    for (let i = 0; i < ActiveFiltersCompTemp.length; i++) {
      if (ActiveFiltersCompTemp[i].name === item.name) {
        ActiveFiltersCompTemp.splice(i, 1);
        setCompActiveFilters(ActiveFiltersCompTemp);
      }
    }
    setFilterTabShow(true);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: ActiveFiltersCompTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
  };

  const IsInAllChannelFilters = (item) => {
    for (let i = 0; i < CompActiveFilters.length; i++) {
      if (CompActiveFilters[i].name === item.name) {
        return true
      }
    }
    return false
  }

  const onChannelDragEnd = (result) => {
    if (!result.destination) return;

    if (
      result.destination.droppableId === "active" &&
      result.source.droppableId === "active"
    ) {
      const ActiveFiltersCompTemp = JSON.parse(
        JSON.stringify(CompActiveFilters)
      );
      ActiveFiltersCompTemp.splice(result.source.index, 1);
      ActiveFiltersCompTemp.splice(
        result.destination.index,
        0,
        CompActiveFilters[result.source.index]
      );
      setCompActiveFilters(ActiveFiltersCompTemp);

      //Set campaign filter data in localstorage
      const CampaignAnalyticFilter = {
        CompAllFilters: CompAllFilters,
        CompActiveFilters: ActiveFiltersCompTemp
      };

      localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
    }
  };

  const onChannelChangeFilter = (selected, index, key) => {
    const CompActiveFiltersTemp = JSON.parse(JSON.stringify(CompActiveFilters));
    // if (selected.length === 0) {
    //   return;
    // }
    CompActiveFiltersTemp[index][key] = selected;
    setCompActiveFilters(CompActiveFiltersTemp);

    //Set campaign filter data in localstorage
    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: CompActiveFiltersTemp
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));
  };




  //  prepare BreadCrumbs....for Measurement, Comparison, and Trend Chart..................................
  useEffect(() => {
    if (ActiveChart === "Measurement") {
      const CHART_DATA = [structuredClone(Chartdata)];
      const [cloneOfChartdata] = CHART_DATA;
      // console.log("cloneOfChartdata: ", cloneOfChartdata);
      const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);
      const listToStoreLength = [];
      for (const key in cloneOfChartdata) {
        const list = cloneOfChartdata[key]["selection"];
        // console.log("list: ", list);
        if(list.length === 0) {
          break;
        }
        for (const name of list) {
          const arr = name.split("-");
          listToStoreLength.push(arr.length);
        }
      }
      // console.log("listToStoreLength: ", listToStoreLength);

      let maxNumber = Math.max(...listToStoreLength);

      if (listToStoreLength.length === 0) {
        maxNumber = 0;
      }
      // console.log("maxNumber: ", maxNumber);
      setBreadCrumbs(allKeysAlreadyInChartdata.slice(0, maxNumber + 1));
    } else if (ActiveChart === "Comparison") {
      const CHART_DATA = [
        structuredClone(ChartdataComp1),
        structuredClone(ChartdataComp2),
      ];
      const [cloneOfChartdata1, cloneOfChartdata2] = CHART_DATA;
      // console.log("cloneOfChartdata: ", cloneOfChartdata);
      const allKeysAlreadyInChartdata1 = Object.keys(cloneOfChartdata1);
      const allKeysAlreadyInChartdata2 = Object.keys(cloneOfChartdata2);
      const listToStoreLength1 = [];
      const listToStoreLength2 = [];

      for (const key in cloneOfChartdata1) {
        const list = cloneOfChartdata1[key]["selection"];
        // console.log("list: ", list);
        if(list.length === 0) {
          break;
        }
        for (const name of list) {
          const arr = name.split("-");
          listToStoreLength1.push(arr.length);
        }
      }

      for (const key in cloneOfChartdata2) {
        const list = cloneOfChartdata2[key]["selection"];
        // console.log("list: ", list);
        if(list.length === 0) {
          break;
        }
        for (const name of list) {
          const arr = name.split("-");
          listToStoreLength2.push(arr.length);
        }
      }
      // console.log("listToStoreLength: ", listToStoreLength);

      let maxNumber1 = Math.max(...listToStoreLength1);

      if (listToStoreLength1.length === 0) {
        maxNumber1 = 0;
      }

      let maxNumber2 = Math.max(...listToStoreLength2);

      if (listToStoreLength2.length === 0) {
        maxNumber2 = 0;
      }
      // console.log("maxNumber: ", maxNumber);
      setBreadCrumbsComp1(allKeysAlreadyInChartdata1.slice(0, maxNumber1 + 1));
      setBreadCrumbsComp2(allKeysAlreadyInChartdata2.slice(0, maxNumber2 + 1));
    } else {
      const CHART_DATA = [structuredClone(ChartdataTrends)];
      const [cloneOfChartdata] = CHART_DATA;
      // console.log("cloneOfChartdata: ", cloneOfChartdata);
      const allKeysAlreadyInChartdata = Object.keys(cloneOfChartdata);
      const listToStoreLength = [];
      for (const key in cloneOfChartdata) {
        const list = cloneOfChartdata[key]["selection"];
        // console.log("list: ", list);
        if(list.length === 0) {
          break;
        }
        for (const name of list) {
          const arr = name.split("-");
          // console.log("arr: ", arr);
          listToStoreLength.push(arr.length);
        }
      }
      // console.log("listToStoreLength: ", listToStoreLength);

      let maxNumber = Math.max(...listToStoreLength);

      if (listToStoreLength.length === 0) {
        maxNumber = 0;
      }
      // console.log("maxNumber: ", maxNumber);
      setBreadCrumbsTrends(allKeysAlreadyInChartdata.slice(0, maxNumber + 1));

    }
  }, [ActiveChart, Chartdata, ChartdataComp1, ChartdataComp2, ChartdataTrends]);
 
 
  


  // Drill up and drill down of measurement chart  ====================================================
  
  // for tree button
  const DrillAll = () => {
    // let ChartdataTemp = {};
    // for (const filter in Chartdata) {
    //   ChartdataTemp[filter] = Chartdata[filter];
    //   ChartdataTemp[filter].selection = [];
    // }

    // console.log("NotAllow: ", NotAllow);

     // new code ................
     const keysArrayOfChartData = Object.keys(Chartdata);

     // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);
 
     // console.log("not allow list: ", NotAllow);
 
     const ChartdataTemp = structuredClone(Chartdata);
 
     function createArrays(numberOfArrays) {
       const arrays = [];
     
       for (let i = 0; i < numberOfArrays; i++) {
         arrays.push([]);
       }
     
       return arrays;
     }
     
     // Example usage:
     const result = createArrays(keysArrayOfChartData.length);
     // console.log("numbers of arrays: ", result);
 
     // result.map((item) => {
     //   item.push(...NotAllow);
     //   return "";
     // });
 
    result.forEach(item => {
     return item.push(...NotAllow);
    })
 
     // console.log("resulted array: ", result)
 
 
     // const payloadArray = result.map((item, index) => {
     //   return [item[0].split("-").slice(0, index + 1).join("-")];
     // })
 
     const payloadArray = result.map((item, index) => {
          const itemArray = item.map(key => {
            return key.split("-").slice(0, index + 1).join("-");
          })
 
         //  return itemArray;
        return [...new Set(itemArray)]
     })
 
 
     // console.log("output arrays payload: ", payloadArray);
 
     keysArrayOfChartData.slice(0, keysArrayOfChartData.length - 1).forEach((key, index) => {
       if(ChartdataTemp.hasOwnProperty(key)) {
         // console.log("keyssssssssssssss: ", key);
         ChartdataTemp[key].selection = payloadArray[index];
       }
     })
 
     // console.log("ChartdataTrendsTemp: ", ChartdataTrendsTemp);
 
     // new code ended..............
 
    setIsAllEnabled(1);
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(1);
  };

  
  // for reset
  const ResetAll = () => {
    let ChartdataTemp = {};
    for (const filter in Chartdata) {
      ChartdataTemp[filter] = Chartdata[filter];
      ChartdataTemp[filter].selection = [];
    }

    setReset(1);
    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0)

  };

  
  // for point click
  const DrillDown = (category) => {
    // console.log("clicked category: ", category);


    const splitNameList = category.split("-");
    const Length = splitNameList.length;


    // console.log("Length: ", Length);


    const allKeysAlreadyInChartdata = Object.keys(Chartdata);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata = allKeysAlreadyInChartdata[Length - 1];

    // console.log("keyToAddSelectionFilterInChartdata: ", keyToAddSelectionFilterInChartdata);
   

    let cloneOfChartdata = structuredClone(Chartdata);

  

    if(cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(category);
    } 


    setChartdata(cloneOfChartdata);
    setFetchCall(true);


    // setClickedCategory(Category);
    setIsAllEnabledDownload(0);
  }; 

  // for label click
  const DrillUp = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("title");
    // console.log("label: ", label);

    
    const ChartdataTemp = structuredClone(Chartdata);

    for (const key in ChartdataTemp) {
      // Remove any values containing "glico-pejoy" from the selection array
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => !value.includes(label)
      );
    }

    setChartdata(ChartdataTemp);
    setFetchCall(true);
    setIsAllEnabledDownload(0);
  };

  // for down arrow
  const DrillDownAll = () => {
      // new code ................
      const keysAlreadyInChartData = Object.keys(Chartdata);

      // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);
  
      // console.log("not allow list: ", NotAllow);
  
      const ChartdataTemp = structuredClone(Chartdata);
  
      function createArrays(numberOfArrays) {
        const arrays = [];
      
        for (let i = 0; i < numberOfArrays; i++) {
          arrays.push([]);
        }
      
        return arrays;
      }
      
      // Example usage:
      const result = createArrays(keysAlreadyInChartData.length);
      // console.log("numbers of arrays: ", result);
  
  
     result.forEach(item => {
      return item.push(...NotAllow);
     })
  
  
  
      const payloadArray = result.map((item, index) => {
           const itemArray = item.map(key => {
             return key.split("-").slice(0, index + 1).join("-");
           })
           return [...new Set(itemArray)]
      })
  
  
      // console.log("output arrays payload: ", payloadArray);

      const LENGTH = BreadCrumbs.length;

      const chartDataKeysToAddSelection = keysAlreadyInChartData.slice(0 , keysAlreadyInChartData.length - 1);

      // console.log("chartDataKeysToAddSelection: ", chartDataKeysToAddSelection);
  
      // chartDataKeysToAddSelection.slice(0, LENGTH).forEach((key, index) => {
      //   if(ChartdataTemp.hasOwnProperty(key)) {
      //     // console.log("keyssssssssssssss: ", key);
      //     ChartdataTemp[key].selection = payloadArray[index];
      //   }
      // })

      for (let index = 0; index < LENGTH && index < chartDataKeysToAddSelection.length; index++) {
        const key = chartDataKeysToAddSelection[index];
        
        if (ChartdataTemp.hasOwnProperty(key)) {
            if (Chartdata[key].selection.length < payloadArray[index].length) {
                ChartdataTemp[key].selection = payloadArray[index];
                break;
            } else {
                ChartdataTemp[key].selection = payloadArray[index];
            }
        }
    }
    

      // new code ended..............
  
     setChartdata(ChartdataTemp);
     setFetchCall(true);
     setIsAllEnabledDownload(0);

  }

   // for up arrow
  const DrillUpAll = () => {
     // new code ................
      const keysAlreadyInChartData = Object.keys(Chartdata);

      // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);
  
      // console.log("not allow list: ", NotAllow);
  
      const ChartdataTemp = structuredClone(Chartdata);
      // console.log("output arrays payload: ", payloadArray);

      const LENGTH = BreadCrumbs.length;

      // console.log("LENGTH: ", LENGTH);

      const chartDataKeysToRemoveSelection = keysAlreadyInChartData.slice(0 , keysAlreadyInChartData.length - 1);

      // console.log("chartDataKeysToSelection: ", chartDataKeysToRemoveSelection);
  
      chartDataKeysToRemoveSelection.slice(LENGTH - 2, LENGTH).forEach((key) => {
        if(ChartdataTemp.hasOwnProperty(key)) {
          // console.log("keyssssssssssssss: ", key);
          ChartdataTemp[key].selection = [];
        }
      })

      // new code ended..............
  
     setChartdata(ChartdataTemp);
     setFetchCall(true);
     setIsAllEnabledDownload(0);
  };
  // code end for measurement...........................................







  // Drill up and drill down of ccomparison chart 1 ====================================================
  
  // for point click
  const DrillDownComp1 = (category) => {
    const splitNameList = category.split("-");
    const Length = splitNameList.length;


    // console.log("Length: ", Length);


    const allKeysAlreadyInChartdata = Object.keys(ChartdataComp1);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata = allKeysAlreadyInChartdata[Length - 1];

    // console.log("keyToAddSelectionFilterInChartdata: ", keyToAddSelectionFilterInChartdata);
   

    let cloneOfChartdata = structuredClone(ChartdataComp1);

  

    if(cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(category);
    } 


    setChartdataComp1(cloneOfChartdata);
    setFetchCallComp1(true);

    setIsAllEnabledDownload(0)
  };

  // for label click
  const DrillUpComp1 = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("title");
    // console.log("label: ", label);

    
    const ChartdataTemp = structuredClone(ChartdataComp1);

    for (const key in ChartdataTemp) {
      // Remove any values containing "glico-pejoy" from the selection array
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => !value.includes(label)
      );
    }

    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
    setIsAllEnabledDownload(0);
  };


  // for down arrow
  const DrillDownAll1 = () => {
    // new code ................
    const keysAlreadyInChartData = Object.keys(ChartdataComp1);

    // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(ChartdataComp1);

    function createArrays(numberOfArrays) {
      const arrays = [];
    
      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }
    
      return arrays;
    }
    
    // Example usage:
    const result = createArrays(keysAlreadyInChartData.length);
    // console.log("numbers of arrays: ", result);


   result.forEach(item => {
    return item.push(...NotAllow);
   })



    const payloadArray = result.map((item, index) => {
         const itemArray = item.map(key => {
           return key.split("-").slice(0, index + 1).join("-");
         })
         return [...new Set(itemArray)]
    })


    // console.log("output arrays payload: ", payloadArray);

    const LENGTH = BreadCrumbsComp1.length;

    const chartDataKeysToAddSelection = keysAlreadyInChartData.slice(0 , keysAlreadyInChartData.length - 1);

    // console.log("chartDataKeysToAddSelection: ", chartDataKeysToAddSelection);

    // chartDataKeysToAddSelection.slice(0, LENGTH).forEach((key, index) => {
    //   if(ChartdataTemp.hasOwnProperty(key)) {
    //     // console.log("keyssssssssssssss: ", key);
    //     ChartdataTemp[key].selection = payloadArray[index];
    //   }
    // })

    for (let index = 0; index < LENGTH && index < chartDataKeysToAddSelection.length; index++) {
      const key = chartDataKeysToAddSelection[index];
      
      if (ChartdataTemp.hasOwnProperty(key)) {
          if (ChartdataComp1[key].selection.length < payloadArray[index].length) {
              ChartdataTemp[key].selection = payloadArray[index];
              break;
          } else {
              ChartdataTemp[key].selection = payloadArray[index];
          }
      }
  }
  

    // new code ended..............

   setChartdataComp1(ChartdataTemp);
   setFetchCallComp1(true);
   setIsAllEnabledDownload(0);

}

  // for tree button
  const DrillAll1 = () => {
    const keysArrayOfChartData = Object.keys(ChartdataComp1);

    // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(ChartdataComp1);

    function createArrays(numberOfArrays) {
      const arrays = [];
    
      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }
    
      return arrays;
    }
    
    // Example usage:
    const result = createArrays(keysArrayOfChartData.length);
    // console.log("numbers of arrays: ", result);

    // result.map((item) => {
    //   item.push(...NotAllow);
    //   return "";
    // });

   result.forEach(item => {
    return item.push(...NotAllow);
   })

    // console.log("resulted array: ", result)


    // const payloadArray = result.map((item, index) => {
    //   return [item[0].split("-").slice(0, index + 1).join("-")];
    // })

    const payloadArray = result.map((item, index) => {
         const itemArray = item.map(key => {
           return key.split("-").slice(0, index + 1).join("-");
         })

        //  return itemArray;
       return [...new Set(itemArray)]
    })


    // console.log("output arrays payload: ", payloadArray);

    keysArrayOfChartData.slice(0, keysArrayOfChartData.length - 1).forEach((key, index) => {
      if(ChartdataTemp.hasOwnProperty(key)) {
        // console.log("keyssssssssssssss: ", key);
        ChartdataTemp[key].selection = payloadArray[index];
      }
    })


    setIsAllEnabled(1);
    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
    setIsAllEnabledDownload(1)

  };

  const ResetAll1 = () => {
    let ChartdataTemp = {};
    for (const filter in ChartdataComp1) {
      ChartdataTemp[filter] = ChartdataComp1[filter];
      ChartdataTemp[filter].selection = [];
    }

    setReset(1);
    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
    setIsAllEnabledDownload(0)

  };


  // for up arrow
  const DrillUpAll1 = () => {
    // new code ................
     const keysAlreadyInChartData = Object.keys(ChartdataComp1);

     // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);
 
     // console.log("not allow list: ", NotAllow);
 
     const ChartdataTemp = structuredClone(ChartdataComp1);
     // console.log("output arrays payload: ", payloadArray);

     const LENGTH = BreadCrumbsComp1.length;

     // console.log("LENGTH: ", LENGTH);

     const chartDataKeysToRemoveSelection = keysAlreadyInChartData.slice(0 , keysAlreadyInChartData.length - 1);

     // console.log("chartDataKeysToSelection: ", chartDataKeysToRemoveSelection);
 
     chartDataKeysToRemoveSelection.slice(LENGTH - 2, LENGTH).forEach((key) => {
       if(ChartdataTemp.hasOwnProperty(key)) {
         // console.log("keyssssssssssssss: ", key);
         ChartdataTemp[key].selection = [];
       }
     })

     // new code ended..............
 
    setChartdataComp1(ChartdataTemp);
    setFetchCallComp1(true);
    setIsAllEnabledDownload(0);
 };

  /// Drill up and drill down of ccomparison chart 2  ===============================================
  // for point click
  const DrillDownComp2 = (category) => {
    const splitNameList = category.split("-");
    const Length = splitNameList.length;


    // console.log("Length: ", Length);


    const allKeysAlreadyInChartdata = Object.keys(ChartdataComp2);
    // console.log("allKeysAlreadyInChartdatra: ", allKeysAlreadyInChartdata);

    const keyToAddSelectionFilterInChartdata = allKeysAlreadyInChartdata[Length - 1];

    // console.log("keyToAddSelectionFilterInChartdata: ", keyToAddSelectionFilterInChartdata);
   

    let cloneOfChartdata = structuredClone(ChartdataComp2);

  

    if(cloneOfChartdata.hasOwnProperty(keyToAddSelectionFilterInChartdata)) {
      cloneOfChartdata[keyToAddSelectionFilterInChartdata].selection.push(category);
    } 


    setChartdataComp2(cloneOfChartdata);
    setFetchCallComp2(true);

    setIsAllEnabledDownloadC2(0)
  };

  // for label click
  const DrillUpComp2 = (category) => {
    // console.log("category: ", category.value);
    var span = document.createElement("span");
    span.innerHTML = category.value;
    const label = span.firstChild.getAttribute("title");
    // console.log("label: ", label);

    
    const ChartdataTemp = structuredClone(ChartdataComp2);

    for (const key in ChartdataTemp) {
      // Remove any values containing "glico-pejoy" from the selection array
      ChartdataTemp[key].selection = ChartdataTemp[key].selection.filter(
        (value) => !value.includes(label)
      );
    }

    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
    setIsAllEnabledDownloadC2(0);
  };


  // for down arrow
  const DrillDownAll2 = () => {
    // new code ................
    const keysAlreadyInChartData = Object.keys(ChartdataComp2);

    // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(ChartdataComp2);

    function createArrays(numberOfArrays) {
      const arrays = [];
    
      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }
    
      return arrays;
    }
    
    // Example usage:
    const result = createArrays(keysAlreadyInChartData.length);
    // console.log("numbers of arrays: ", result);


   result.forEach(item => {
    return item.push(...NotAllow);
   })



    const payloadArray = result.map((item, index) => {
         const itemArray = item.map(key => {
           return key.split("-").slice(0, index + 1).join("-");
         })
         return [...new Set(itemArray)]
    })


    // console.log("output arrays payload: ", payloadArray);

    const LENGTH = BreadCrumbsComp1.length;

    const chartDataKeysToAddSelection = keysAlreadyInChartData.slice(0 , keysAlreadyInChartData.length - 1);

    // console.log("chartDataKeysToAddSelection: ", chartDataKeysToAddSelection);

    // chartDataKeysToAddSelection.slice(0, LENGTH).forEach((key, index) => {
    //   if(ChartdataTemp.hasOwnProperty(key)) {
    //     // console.log("keyssssssssssssss: ", key);
    //     ChartdataTemp[key].selection = payloadArray[index];
    //   }
    // })

    for (let index = 0; index < LENGTH && index < chartDataKeysToAddSelection.length; index++) {
      const key = chartDataKeysToAddSelection[index];
      
      if (ChartdataTemp.hasOwnProperty(key)) {
          if (ChartdataComp1[key].selection.length < payloadArray[index].length) {
              ChartdataTemp[key].selection = payloadArray[index];
              break;
          } else {
              ChartdataTemp[key].selection = payloadArray[index];
          }
      }
  }
  

    // new code ended..............

   setChartdataComp2(ChartdataTemp);
   setFetchCallComp2(true);
   setIsAllEnabledDownloadC2(0);

}

  // for tree button
  const DrillAll2 = () => {
    const keysArrayOfChartData = Object.keys(ChartdataComp2);

    // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTemp = structuredClone(ChartdataComp2);

    function createArrays(numberOfArrays) {
      const arrays = [];
    
      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }
    
      return arrays;
    }
    
    // Example usage:
    const result = createArrays(keysArrayOfChartData.length);
    // console.log("numbers of arrays: ", result);

    // result.map((item) => {
    //   item.push(...NotAllow);
    //   return "";
    // });

   result.forEach(item => {
    return item.push(...NotAllow);
   })

    // console.log("resulted array: ", result)


    // const payloadArray = result.map((item, index) => {
    //   return [item[0].split("-").slice(0, index + 1).join("-")];
    // })

    const payloadArray = result.map((item, index) => {
         const itemArray = item.map(key => {
           return key.split("-").slice(0, index + 1).join("-");
         })

        //  return itemArray;
       return [...new Set(itemArray)]
    })


    // console.log("output arrays payload: ", payloadArray);

    keysArrayOfChartData.slice(0, keysArrayOfChartData.length - 1).forEach((key, index) => {
      if(ChartdataTemp.hasOwnProperty(key)) {
        // console.log("keyssssssssssssss: ", key);
        ChartdataTemp[key].selection = payloadArray[index];
      }
    })


    setIsAllEnabled(1);
    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
    setIsAllEnabledDownloadC2(1)

  };

  const ResetAll2 = () => {
    let ChartdataTemp = {};
    for (const filter in ChartdataComp2) {
      ChartdataTemp[filter] = ChartdataComp1[filter];
      ChartdataTemp[filter].selection = [];
    }

    setReset(1);
    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
    setIsAllEnabledDownloadC2(0)

  };


  // for up arrow
  const DrillUpAll2 = () => {
    // new code ................
     const keysAlreadyInChartData = Object.keys(ChartdataComp2);

     // console.log("keysAlreadyInChartData Trend: ", keysAlreadyInChartData);
 
     // console.log("not allow list: ", NotAllow);
 
     const ChartdataTemp = structuredClone(ChartdataComp2);
     // console.log("output arrays payload: ", payloadArray);

     const LENGTH = BreadCrumbsComp2.length;

     // console.log("LENGTH: ", LENGTH);

     const chartDataKeysToRemoveSelection = keysAlreadyInChartData.slice(0 , keysAlreadyInChartData.length - 1);

     // console.log("chartDataKeysToSelection: ", chartDataKeysToRemoveSelection);
 
     chartDataKeysToRemoveSelection.slice(LENGTH - 2, LENGTH).forEach((key) => {
       if(ChartdataTemp.hasOwnProperty(key)) {
         // console.log("keyssssssssssssss: ", key);
         ChartdataTemp[key].selection = [];
       }
     })

     // new code ended..............
 
    setChartdataComp2(ChartdataTemp);
    setFetchCallComp2(true);
    setIsAllEnabledDownloadC2(0);
 };
  // =================================================================================================

  // for trends start................................................
  // ===============================================================================
  const DrillDownTrends = (Category) => {  // for point click
    // console.log("Category: ", Category.series.name);
   
    const arr = Category.series.name.split("-");
    const Length = arr.length -1

    // console.log("Length: ", Length);

    const keysArrayOfChartData = Object.keys(ChartdataTrends);
    // console.log("keysArrayOfChartData: ", keysArrayOfChartData);

    const keyToAddSelectionFilter = keysArrayOfChartData[Length];

    // console.log("keyToAddSelection: ", keyToAddSelectionFilter);
   

    let resultObject = structuredClone(ChartdataTrends);

  

    if(resultObject.hasOwnProperty(keyToAddSelectionFilter)) {
      resultObject[keyToAddSelectionFilter].selection.push(Category.series.name);
    } 


    // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, Length + 2));
    setChartdataTrends(resultObject);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(0)

    


    // console.log("resultObject: ", resultObject);

  };


  const DrillDownAllTrends = () => {  // for down arrow
  

    // console.log("is all enabled: ", IsAllEnabled);
    
    const arr = [];
    const seriesName = seriesTrends.map(item => {
       arr.push(item.name.split("-").length);
      return item.name;
    })
    
    const minLength = Math.min(...arr)

    const payloadSeriesName = seriesName.map(item => {
        const newItem = item.split("-");

        const a = newItem.slice(0, minLength);
        const b = a.join("-");

        return b;


    })
    // console.log("seriesTrends: ", seriesTrends);
    // console.log("Not allow list: ", NotAllow);
    // console.log("clicked drill down all series payload: ", payloadSeriesName);
    // console.log("series array length: ", minLength - 1);


    const keysArrayOfChartData = Object.keys(ChartdataTrends);

    // console.log("keysArrayOfChartData: ", keysArrayOfChartData.length, minLength);

    const keyToAddSelectionFilter = keysArrayOfChartData[minLength - 1];

    // console.log("keyToAddSelection: ", keyToAddSelectionFilter);
   

    let resultObject = structuredClone(ChartdataTrends);

  

    if(minLength < keysArrayOfChartData.length && resultObject.hasOwnProperty(keyToAddSelectionFilter)) {
      resultObject[keyToAddSelectionFilter].selection = payloadSeriesName;
    } 
    // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, minLength + 1));
    setChartdataTrends(resultObject);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(0)
  }

  // useEffect(() => {
  //    if(ActiveChart === "Trend_Analysis") {
  //     setBreadCrumbsTrends([Fixed[0].id]);
  //    }
  // }, [ActiveChart, ActiveFilters, CompActiveFilters])

  
  const DrillAllTrends = () => {  // for tree button
    let ChartdataTemp = {};
    for (const filter in ChartdataTrends) {
      ChartdataTemp[filter] = ChartdataTrends[filter];
      ChartdataTemp[filter].selection = [];
    }


    // new code ................
    const keysArrayOfChartData = Object.keys(ChartdataTrends);

    // console.log("keysArrayOfChartData Trend: ", keysArrayOfChartData);

    // console.log("not allow list: ", NotAllow);

    const ChartdataTrendsTemp = structuredClone(ChartdataTrends);

    function createArrays(numberOfArrays) {
      const arrays = [];
    
      for (let i = 0; i < numberOfArrays; i++) {
        arrays.push([]);
      }
    
      return arrays;
    }
    
    // Example usage:
    const result = createArrays(keysArrayOfChartData.length);
    // console.log("numbers of arrays: ", result);

    // result.map((item) => {
    //   item.push(...NotAllow);
    //   return "";
    // });

   result.forEach(item => {
    return item.push(...NotAllow);
   })

    // console.log("resulted array: ", result)


    // const payloadArray = result.map((item, index) => {
    //   return [item[0].split("-").slice(0, index + 1).join("-")];
    // })

    const payloadArray = result.map((item, index) => {
         const itemArray = item.map(key => {
           return key.split("-").slice(0, index + 1).join("-");
         })

        //  return itemArray;
       return [...new Set(itemArray)]
    })


    // console.log("output arrays payload: ", payloadArray);

    keysArrayOfChartData.slice(0, keysArrayOfChartData.length - 1).forEach((key, index) => {
      if(ChartdataTrendsTemp.hasOwnProperty(key)) {
        // console.log("keyssssssssssssss: ", key);
        ChartdataTrendsTemp[key].selection = payloadArray[index];
      }
    })

    // console.log("ChartdataTrendsTemp: ", ChartdataTrendsTemp);

    // new code ended..............

    let BreadCrumbsTrendsTemp = [];
    BreadCrumbsTrendsTemp =  Object.keys(ChartdataTrends);

    // setBreadCrumbsTrends(BreadCrumbsTrendsTemp);

    setIsAllEnabled(1);
    // setChartdataTrends(ChartdataTemp);
    setChartdataTrends(ChartdataTrendsTemp);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(1)

  };

  const ResetAllTrends = () => {   // fro reset button
    let ChartdataTemp = {};
    for (const filter in ChartdataTrends) {
      ChartdataTemp[filter] = ChartdataTrends[filter];
      ChartdataTemp[filter].selection = [];
    }


    let BreadCrumbsTrendsTemp = [];
    BreadCrumbsTrendsTemp =  Object.keys(ChartdataTrends);

    // setBreadCrumbsTrends([BreadCrumbsTrendsTemp[0]]);
    setReset(1);
    setChartdataTrends(ChartdataTemp);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(0)

  };
  

  // for arrow up
  const DrillUpAllTrends = () => {
    // console.log("drill up all called: ")

    const arr = [];
    const seriesName = seriesTrends.map(item => {
       arr.push(item.name.split("-").length);
      return item.name;
    })
    
    const maxLength = Math.max(...arr)

    const payloadSeriesName = seriesName.map(item => {
        const newItem = item.split("-");

        const a = newItem.slice(0, maxLength - 2);
        const b = a.join("-");
        

        return b;


    })
    // console.log("clicked drill up all series payload: ", payloadSeriesName);
    // console.log("series array length: ", maxLength - 1);


    const keysArrayOfChartData = Object.keys(ChartdataTrends);
    // console.log("keysArrayOfChartData: ", keysArrayOfChartData);
    const BreadCrumbsTrendsLength = BreadCrumbsTrends.length;

    // console.log("BreadCrumbsTrendsLength: ", BreadCrumbsTrendsLength);
    const keyToAddSelectionFilter = keysArrayOfChartData[maxLength - 3];

    // console.log("keyToAddSelection: ", keyToAddSelectionFilter);
    // console.log("keys array length: ", keysArrayOfChartData.length);

    const keyToAddSelectionFilterIndex = keysArrayOfChartData.indexOf(keyToAddSelectionFilter)
    // console.log("keys array index: ", keyToAddSelectionFilterIndex)
   

    let resultObject = structuredClone(ChartdataTrends);

  
    const removeDuplicates = (arr) => {
      const uniqueArray = arr.filter((item, index) => {
          return arr.indexOf(item) === index;
      });
  
      return uniqueArray;
  };
  
  // Example usage
   let updatedPayload = removeDuplicates(payloadSeriesName);

    // console.log("updatedPayload in drill up: ", JSON.stringify(updatedPayload));
  

    if(JSON.stringify(updatedPayload) === '[""]') {
      updatedPayload = [];
    }

    if(keyToAddSelectionFilterIndex === -1) {
       let KEY = keysArrayOfChartData[0];
     if( resultObject.hasOwnProperty(KEY)) {
        resultObject[KEY].selection = updatedPayload;
        // console.log("[Fixed[0].id]: ", [Fixed[0].id]);
      }
      setBreadCrumbsTrends([KEY]);
    }
      else if(resultObject.hasOwnProperty(keyToAddSelectionFilter)) {
      resultObject[keyToAddSelectionFilter].selection = updatedPayload;
      let start = keyToAddSelectionFilterIndex + 1;
      for(let i = start; i < BreadCrumbsTrendsLength; i++) {
        resultObject[keysArrayOfChartData[i]].selection = [];
      }
      // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, maxLength - 1));
    } 
    // setBreadCrumbsTrends(keysArrayOfChartData.slice(0, maxLength - 1));
    setChartdataTrends(resultObject);
    setFetchCallTrends(true);
    setIsAllEnabledDownload(1)


  


  };


  // end for trends=========================================================================================

  const HandleZoom = () => {
    setLoader(true)
    const categoriesTemp = JSON.parse(JSON.stringify(categories))
    const seriesTemp = JSON.parse(JSON.stringify(series))

    setCategories([])
    setSeries([])

    setZoomIn(!ZoomIn);
    setTimeout(function () {
      setCategories(categoriesTemp)
      setSeries(seriesTemp)
      setLoader(false)
    }, 1000);
  }

  const ResetFilters = () => {
    var active_filter = []
    for (let i = 0; i < ActiveFilters.length; i++) {
      var obj = ActiveFilters[i];
      if (ActiveChart === "Measurement") {
        obj['Selected'] = [];
        setMResetFilterFlag(false)
      }

      if (ActiveChart === "Trend_Analysis") {
        obj['Selected'] = [];
        setMResetFilterFlag(false)
      }
      if (ActiveChart === "Comparison") {
        obj['Selected1'] = [];
        obj['Selected2'] = [];
        setCResetFilterFlag(false)

      }

      active_filter.push(obj)
    }

    var comp_active_filter = []
    for (let k = 0; k < CompActiveFilters.length; k++) {
      var obj1 = CompActiveFilters[k];
      if (ActiveChart === "Measurement") {
        obj1['Selected'] = [];
        setMResetFilterFlag(false)
      }

      if (ActiveChart === "Trend_Analysis") {
        obj1['Selected'] = [];
        setMResetFilterFlag(false)
      }
      if (ActiveChart === "Comparison") {
        obj1['Selected1'] = [];
        obj1['Selected2'] = [];
        setCResetFilterFlag(false)
      }
      comp_active_filter.push(obj1)
    }
    //Set analytical filters data in localstorage
    const AnalyticFilterTemp = {
      AllFilters: AllFilters,
      Fixed: Fixed,
      ActiveFilters: active_filter
    };

    localStorage.setItem("AnalyticFilter", JSON.stringify(AnalyticFilterTemp));

    const CampaignAnalyticFilter = {
      CompAllFilters: CompAllFilters,
      CompActiveFilters: comp_active_filter
    };

    localStorage.setItem("CampaignAnalyticFilter", JSON.stringify(CampaignAnalyticFilter));

    setActiveFilters(active_filter)
    setCompActiveFilters(comp_active_filter)
  }

  const downloadDataM = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("grain_contrib/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframe,
        startdate: DefaultStartDate,
        enddate: DefaultEndDate,
        is_all_enabled: IsAllEnabledDownload,
        reset: Reset,
        chartdata: Chartdata,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };
 

  const downloadPDFM = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [
      document.getElementById("campaign_chart"),
    ];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("grain_contrib/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframe,
          startdate: DefaultStartDate,
          enddate: DefaultEndDate,
          is_all_enabled: IsAllEnabledDownload,
          reset: Reset,
          chartdata: Chartdata,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
          images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
          document: {
            table1_data: response.data.table1_data,
            titlename: "Campaign Effectiveness",
            footer_content: "Private & Confidential",
            table_heading1: "Measurement Deepdive",
            image_heading1: "Measurement Deepdive",
            username: userName,
            timeframe: timeframe,
            start_date: DefaultStartDate,
            end_date: DefaultEndDate,
            category: "",
            chartdata: Chartdata,
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            tabname: "grain_contri"
          },
        });
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const downloadDataC1 = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("grain_contrib/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframeComp1,
        startdate: DefaultStartDateComp1,
        enddate: DefaultEndDateComp1,
        is_all_enabled: IsAllEnabledDownload,
        reset: Reset,
        chartdata: ChartdataComp1,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };
  const downloadPDFC1 = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [
      document.getElementById("Comp1"),
    ];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("grain_contrib/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframeComp1,
          startdate: DefaultStartDateComp1,
          enddate: DefaultEndDateComp1,
          is_all_enabled: IsAllEnabledDownload,
          reset: Reset,
          chartdata: ChartdataComp1,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
          images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
          document: {
            table1_data: response.data.table1_data,
            titlename: "Campaign Effectiveness",
            footer_content: "Private & Confidential",
            table_heading1: "Measurement Deepdive",
            image_heading1: "Measurement Deepdive",
            username: userName,
            timeframe: timeframeComp1,
            start_date: DefaultStartDateComp1,
            end_date: DefaultEndDateComp1,
            category: "",
            chartdata: ChartdataComp1,
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            tabname: "grain_contri",
          },
        });
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };

  const downloadDataC2 = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("grain_contrib/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframeComp2,
        startdate: DefaultStartDateComp2,
        enddate: DefaultEndDateComp2,
        is_all_enabled: IsAllEnabledDownloadC2,
        reset: Reset,
        chartdata: ChartdataComp2,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };

  const downloadPDFC2 = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [
      document.getElementById("Comp2"),
    ];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("grain_contrib/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframeComp2,
          startdate: DefaultStartDateComp2,
          enddate: DefaultEndDateComp2,
          is_all_enabled: IsAllEnabledDownloadC2,
          reset: Reset,
          chartdata: ChartdataComp2,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
          images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
          document: {
            table1_data: response.data.table1_data,
            titlename: "Campaign Effectiveness",
            footer_content: "Private & Confidential",
            table_heading1: "Measurement Deepdive",
            image_heading1: "Measurement Deepdive",
            username: userName,
            timeframe: timeframeComp2,
            start_date: DefaultStartDateComp2,
            end_date: DefaultEndDateComp2,
            category: "",
            chartdata: ChartdataComp2,
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            tabname: "grain_contri",
          },
        });
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };


  const downloadDataT = (e) => {
    e.preventDefault();
    setLoader(true);
    axiosDownload
      .post("stack/", {
        brand: Analyse.Brand,
        kpi: Analyse.KPI,
        timeframe: timeframeTrends,
        startdate: DefaultStartDateTrends,
        enddate: DefaultEndDateTrends,
        is_all_enabled: IsAllEnabledDownload,
        reset: Reset,
        chartdata: ChartdataTrends,
        theme: localStorage.getItem("CurrentTheme"),
      })
      .then((response) => {
        var link = document.createElement("a");
        link.target = "_blank";
        link.href = response.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoader(false);
      })
      .catch((data) => {
        setLoader(false);
      });
  };


  const downloadPDFT = async (e) => {
    e.preventDefault();
    setLoader(true);
    const chartContainers = [
      document.getElementById("trends_chart"),
    ];
    if (chartContainers.every((container) => container !== null)) {
      try {
        // setLoader(true);
        const dataUrls = await Promise.all(
          chartContainers.map((container) => domtoimage.toPng(container))
        );
        // setLoader(true);
        const response = await axiosDownload.post("stack/", {
          brand: Analyse.Brand,
          kpi: Analyse.KPI,
          timeframe: timeframeTrends,
          startdate: DefaultStartDateTrends,
          enddate: DefaultEndDateTrends,
          is_all_enabled: IsAllEnabledDownload,
          reset: Reset,
          chartdata: ChartdataTrends,
          theme: localStorage.getItem("CurrentTheme"),
          download_type: "PDF",
        });
        // setLoader(false);
        // console.log("excel: ", response.data);
        const pdfResponse = await axiosChartDownload.post("download_pdf/create/", {
          images: dataUrls.map((dataUrl) => dataUrl.split(",")[1]),
          document: {
            table1_data: response.data.table1_data,
            titlename: "Campaign Effectiveness",
            footer_content: "Private & Confidential",
            table_heading1: "Trend Analysis",
            image_heading1: "Trend Analysis",
            username: userName,
            timeframe: timeframeTrends,
            start_date: DefaultStartDateTrends,
            end_date: DefaultEndDateTrends,
            category: "",
            chartdata: ChartdataTrends,
            brand: Analyse.Brand,
            kpi: Analyse.KPI,
            tabname: "grain_contri"
          },
        });
        if (pdfResponse.data.error === 1) {
          toast.error(pdfResponse.data.erroMsg);
          setLoader(false);
        } else {
          // console.log("response pdf: ", pdfResponse.data);
          setLoader(false);
          var link = document.createElement("a");
          link.target = "_blank";
          link.href = pdfResponse.data.pdf_url;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error while generating pdf:", error);
      }
    }
  };


  return (
    <>
      {loader ? <Loader /> : null}

      <ToastContainer theme="colored" />
      <Topbar />
      <Menubar />

      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid main-content-w pt-0">
            <div className="row align-items-center mb-1">
              <div className="col-sm-6">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between p-0">
                  <h1 className="mb-sm-0 theme-color main-title">
                    {Analyse && Analyse.Menubar
                      ? Analyse.Menubar.map((item) => {
                        return "/" + item.key === location.pathname
                          ? item.value
                          : null;
                      })
                      : null}
                  </h1>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 d-flex ce-filter justify-content-end">
                <button
                  className={
                    ActiveChart === "Measurement" ? "btn active custom-input-box" : "btn custom-input-box"
                  }
                  type="button"
                  onClick={() => setActiveChart("Measurement")}
                >
                  Measurement Chart
                </button>
                <button
                  className={
                    ActiveChart === "Comparison" ? "btn active custom-input-box" : "btn custom-input-box"
                  }
                  type="button"
                  onClick={() => setActiveChart("Comparison")}
                >
                  Comparison Charts
                </button>
                {/* Trend Analysis start */}
                <button
                  className={
                    ActiveChart === "Trend_Analysis" ? "btn active custom-input-box" : "btn custom-input-box"
                  }
                  type="button"
                  onClick={() => setActiveChart("Trend_Analysis")}
                >
                  Trend Analysis
                </button>
                {/* Trend Analysis end */}
              </div>
            </div>
            <div className="row mb-0">
              <AllHeirarchyFilter OnlyHeirarchyFilter={false} Fixed={Fixed} ActiveFilters={ActiveFilters} AllFilters={AllFilters} AddHeirarchyFilter={AddHeirarchyFilter} RemoveHeirarchyFilter={RemoveHeirarchyFilter} IsInAllHeirarchyFilters={IsInAllHeirarchyFilters} Chartdata={ChartdataForHierarchyFilters()} />
              <AllCampaignFilter CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} AddCompFilter={AddChannelFilter} RemoveCompFilter={RemoveChannelFilter} IsInAllCompFilters={IsInAllChannelFilters} Chartdata={ChartdataForHierarchyFilters()} />
              <div className="col-xl-2 mt-1">
                <div className="card my-1 mt-0">
                  <div className="card-body cursor-pointer d-flex align-items-center justify-content-center custom-selected-filter" onClick={() => {
                    setFilterTabShow(true);
                  }}>
                    <h6 className="m-0 text-center primary-text"><i className="fa fa-filter" aria-hidden="true"></i> Selected Filter</h6>
                    {ActiveChart === "Measurement" && MResetFilterFlag ?
                      <h6 className="m-0 text-center primary-text cursor-pointer custom-reset" >
                        <p className="mx-2 m-0" title="Reset Filter" onClick={() => {
                          ResetFilters();
                        }}> &#8635; </p>
                      </h6>
                      : null
                    }
                     {ActiveChart === "Comparison" && CResetFilterFlag ?
                      <h6 className="m-0 text-center primary-text cursor-pointer custom-reset" >
                        <p className="mx-2 m-0" title="Reset Filter" onClick={() => {
                          ResetFilters();
                        }}> &#8635; </p>
                      </h6>
                      : null
                    }

                    {ActiveChart === "Trend_Analysis" && MResetFilterFlag ?
                      <h6 className="m-0 text-center primary-text cursor-pointer custom-reset" >
                        <p className="mx-2 m-0" title="Reset Filter" onClick={() => {
                          ResetFilters();
                        }}> &#8635; </p>
                      </h6>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          {
            FilterTabShow && ActiveChart === "Measurement" ?
              <ActiveHeirarchyAndCampaignFilter OnlyHeirarchyFilter={false} onCompChangeFilter={onChannelChangeFilter} onChangeFilter={onChangeHeirarchyFilter} onDragEnd={onHeirarchyDragEnd} ActiveFilters={ActiveFilters} onCompDragEnd={onChannelDragEnd} setFilterTabShow={setFilterTabShow} CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} Fixed={Fixed} />
              : null
          }

          {
            FilterTabShow && ActiveChart === "Comparison" ?
              <CompActiveHeirarchyAndCampaignFilter setFilterTabShow={setFilterTabShow} ActiveFilters={ActiveFilters} onHeirarchyDragEnd={onHeirarchyDragEnd} onChangeHeirarchyFilter={onChangeHeirarchyFilter} CompActiveFilters={CompActiveFilters} onChannelDragEnd={onChannelDragEnd} onChannelChangeFilter={onChannelChangeFilter} />
              : null
          }

          {
            FilterTabShow && ActiveChart === "Trend_Analysis" ?
              <ActiveHeirarchyAndCampaignFilter OnlyHeirarchyFilter={false} onCompChangeFilter={onChannelChangeFilter} onChangeFilter={onChangeHeirarchyFilter} onDragEnd={onHeirarchyDragEnd} ActiveFilters={ActiveFilters} onCompDragEnd={onChannelDragEnd} setFilterTabShow={setFilterTabShow} CompActiveFilters={CompActiveFilters} CompAllFilters={CompAllFilters} Fixed={Fixed} />
              : null
          }


          {ActiveChart === "Measurement" ? (
            <Measurement
              timeframe={timeframe}
              onChangeHandler={onChangeHandler}
              TimeFramesAll={TimeFramesAll}
              DefaultStartDate={DefaultStartDate}
              DefaultEndDate={DefaultEndDate}
              setDefaultStartDate={setDefaultStartDate}
              setDefaultEndDate={setDefaultEndDate}
              FromDatesAll={FromDatesAll}
              ToDatesAll={ToDatesAll}
              setFetchCall={setFetchCall}
              Summary={Summary}
              AllFilters={AllFilters}
              ActiveFilters={ActiveFilters}
              FooterNotes={FooterNotes}
              categories={categories}
              series={series}
              xtitle={xtitle}
              ytitle={ytitle}
              charttitle={charttitle}
              DrillUp={DrillUp}
              DrillDown={DrillDown}
              DrillUpAll={DrillUpAll}
              DrillDownAll={DrillDownAll}
              DrillAll={DrillAll}
              ResetAll={ResetAll}
              BreadCrumbs={BreadCrumbs}
              // setZoomIn={setZoomIn}
              HandleZoom={HandleZoom}
              ZoomIn={ZoomIn}
              NotAllow={NotAllow}
              ShowSummary={ShowSummary}
              // setDownloadchart={setDownloadchart}
              // Downloadchart={Downloadchart}
              setLoader={setLoader}
              Fixed={Fixed}
              setIsDownloadingChart={setIsDownloadingChart}
              setChartContainerID={setChartContainerID}
              setDownloadedImageName={setDownloadedImageName}
              setDownloadingChartTitle={setDownloadingChartTitle}
              downloadDataM={downloadDataM}
              setHierarchy={setHierarchy}
              downloadPDFM={downloadPDFM}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setTimeFrameChart={setTimeFrameChart}
            />
          ) : null}

          {ActiveChart === "Comparison" ? (
            <Comparison
              onChangeHandlerComp1={onChangeHandlerComp1}
              onChangeHandlerComp2={onChangeHandlerComp2}
              timeframeComp1={timeframeComp1}
              TimeFramesAllComp1={TimeFramesAllComp1}
              DefaultStartDateComp1={DefaultStartDateComp1}
              DefaultEndDateComp1={DefaultEndDateComp1}
              setDefaultStartDateComp1={setDefaultStartDateComp1}
              setDefaultEndDateComp1={setDefaultEndDateComp1}
              FromDatesAllComp1={FromDatesAllComp1}
              ToDatesAllComp1={ToDatesAllComp1}
              setFetchCallComp1={setFetchCallComp1}
              categoriesComp1={categoriesComp1}
              seriesComp1={seriesComp1}
              xtitleComp1={xtitleComp1}
              ytitleComp1={ytitleComp1}
              charttitleComp1={charttitleComp1}
              DrillDownComp1={DrillDownComp1}
              DrillUpComp1={DrillUpComp1}
              timeframeComp2={timeframeComp2}
              TimeFramesAllComp2={TimeFramesAllComp2}
              DefaultStartDateComp2={DefaultStartDateComp2}
              DefaultEndDateComp2={DefaultEndDateComp2}
              setDefaultStartDateComp2={setDefaultStartDateComp2}
              setDefaultEndDateComp2={setDefaultEndDateComp2}
              FromDatesAllComp2={FromDatesAllComp2}
              ToDatesAllComp2={ToDatesAllComp2}
              setFetchCallComp2={setFetchCallComp2}
              categoriesComp2={categoriesComp2}
              seriesComp2={seriesComp2}
              xtitleComp2={xtitleComp2}
              ytitleComp2={ytitleComp2}
              charttitleComp2={charttitleComp2}
              DrillDownComp2={DrillDownComp2}
              DrillUpComp2={DrillUpComp2}
              DrillUpAll1={DrillUpAll1}
              DrillDownAll1={DrillDownAll1}
              DrillAll1={DrillAll1}
              ResetAll1={ResetAll1}
              BreadCrumbsComp1={BreadCrumbsComp1}
              DrillUpAll2={DrillUpAll2}
              DrillDownAll2={DrillDownAll2}
              DrillAll2={DrillAll2}
              ResetAll2={ResetAll2}
              BreadCrumbsComp2={BreadCrumbsComp2}
              FooterNotes={FooterNotes}
              NotAllow={NotAllow}
              setIsDownloadingChart={setIsDownloadingChart}
              setChartContainerID={setChartContainerID}
              setDownloadedImageName={setDownloadedImageName}
              setDownloadingChartTitle={setDownloadingChartTitle}
              downloadDataC1={downloadDataC1}
              downloadDataC2={downloadDataC2}
              setHierarchy={setHierarchy}
              downloadPDFC1={downloadPDFC1}
              downloadPDFC2={downloadPDFC2}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setTimeFrameChart={setTimeFrameChart}
            />
          ) : null}

          { ActiveChart === "Trend_Analysis" ? (
            <TrendAnalysis
              timeframe={timeframeTrends}
              onChangeHandler={onChangeHandlerTrends}
              TimeFramesAll={TimeFramesAllTrends}
              DefaultStartDate={DefaultStartDateTrends}
              DefaultEndDate={DefaultEndDateTrends}
              setDefaultStartDate={setDefaultStartDateTrends}
              setDefaultEndDate={setDefaultEndDateTrends}
              FromDatesAll={FromDatesAllTrends}
              ToDatesAll={ToDatesAllTrends}
              setFetchCall={setFetchCallTrends}
              Summary={Summary}
              AllFilters={AllFilters}
              ActiveFilters={ActiveFilters}
              FooterNotes={FooterNotes}
              categories={categoriesTrends}
              series={seriesTrends}
              xtitle={xtitleTrends}
              ytitle={ytitleTrends}
              charttitle={charttitleTrends}
              // DrillUp={DrillUpTrends}
              DrillDown={DrillDownTrends} 
              DrillUpAll={DrillUpAllTrends}
              DrillDownAll={DrillDownAllTrends}
              DrillAll={DrillAllTrends}
              ResetAll={ResetAllTrends}
              BreadCrumbs={BreadCrumbsTrends}
              // setZoomIn={setZoomIn}
              HandleZoom={HandleZoom}
              ZoomIn={ZoomIn}
              NotAllow={NotAllow}
              ShowSummary={ShowSummary}
              // setDownloadchart={setDownloadchart}
              // Downloadchart={Downloadchart}
              setLoader={setLoader}
              Fixed={Fixed}
              showLegend={showLegend}
              setIsDownloadingChart={setIsDownloadingChart}
              setChartContainerID={setChartContainerID}
              setDownloadedImageName={setDownloadedImageName}
              setDownloadingChartTitle={setDownloadingChartTitle}
              setHierarchy={setHierarchy}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setTimeFrameChart={setTimeFrameChart}
              downloadDataT={downloadDataT}
              downloadPDFT={downloadPDFT}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Main;
