import React, { useEffect, useState } from "react";
import Loader from "../../Components/Loader";
import axios from "../../axios";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const UserAccessPopUp = (props) => {
    const [loader, setLoader] = useState(false);
    const [AllHierarchy, setAllHierarchy] = useState([]);

    useEffect(() => {
        setLoader(true);
        axios.post("get_master_hirarchy/").then((response) => {
            if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
            } else {
                var data = response.data.data.master;
                var arr = [];
                for (var i = 0; i < data.length; i++) {
                    var obj = data[i];
                    if (i === 0) {
                        obj["enable"] = true;
                    }
                    obj["active"] = false;
                    obj["selected_option"] = "";
                    obj["options"] = [];
                    arr.push(obj);
                }
                setAllHierarchy(arr)
            }
            setLoader(false);
        });

    }, [])

    const valueChangeHandler = (index, active) => {
        let data = [...AllHierarchy];
        data[index]['active'] = !active;
        data[index + 1]["enable"] = true;

        setLoader(true);
        let payload = {
            "current_hirarchy": data[index].name,
            "old_hirarchy": index !== 0 ? data[index - 1].name : "",
            "old_value": index !== 0 ? data[index - 1].selected_option : ""
        }

        axios.post("get_master_hirarchy_values/", payload).then((response) => {
            if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
            } else {
                data[index]["options"] = response.data.data.master;
                data[index]["selected_option"] = response.data.data.master[0].key;
            }
            setLoader(false);
        });


        setAllHierarchy(data);
    }

    const BrandHandler = (index, value) => {
        let data = [...AllHierarchy];
        data[index]['selected_option'] = value;
        setAllHierarchy(data);
    }

    const HandleSubmit = () => {

        var payload = { user_id: props.UserAccess.id };

        for (var j = 0; j < AllHierarchy.length; j++) {
            payload[AllHierarchy[j].name] = AllHierarchy[j].selected_option === "" ? "All" : AllHierarchy[j].selected_option
        }

        setLoader(true);
        axios.post("update_user_hirarchy/", payload).then((response) => {
            if (response.data.error === 1) {
                toast.error(response.data.erroMsg);
            } else {
                toast.success(response.data.data)
            }
            setLoader(false);
        });

    }

    return (
        <>
            {loader ? <Loader /> : null}
            <div
                className="modal fade bs-example-modal-center show"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="mySmallModalLabel"
                aria-hidden="true"
                style={{ display: "block" }}
            >
                <div className="modal-dialog modal-dialog-centered" style={{ maxWidth: "40%" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="">
                                <h5 className="modal-title " style={{ marginLeft: "306px" }}>User Access</h5>
                            </div>
                        </div>
                        <div className="modal-body modal-body-custom custom-summary">
                            {/* Blocks */}
                            <div className="row custom-body">
                                <div className="col-xs-1 col-sm-3 col-md-4">
                                    <div className="col-12">
                                        <div className="card text-center summary-box">
                                            <div className="card-body card-gradient px-1 " >
                                                <div className="summary-box-content" style={{height:"auto"}} >
                                                    <p className="p-1 m-0 ">User name</p>
                                                    <p className="pb-1 m-0 summary-footnote"><b>{props.UserAccess.firstname + "" + props.UserAccess.lastname}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-4">
                                    <div className="col-12">
                                        <div className="card text-center summary-box">
                                            <div className="card-body card-gradient px-1" >
                                                <div className="summary-box-content" style={{height:"auto"}}>
                                                    <p className="p-1 m-0 ">UserId</p>
                                                    <p className="pb-1 m-0 summary-footnote"><b>{props.UserAccess.username}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-xs-3 col-sm-3 col-md-4">
                                    <div className="col-12">
                                        <div className="card text-center summary-box">
                                            <div className="card-body card-gradient px-1" >
                                                <div className="summary-box-content" style={{height:"auto"}}>
                                                    <p className="p-1 m-0">Deactivation Date</p>
                                                    <p className="pb-1 m-0 summary-footnote"><b>{props.UserAccess.deactivation_date}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            {/* Table */}
                            <div className="row justify-content-between mx-2 mt-3">
                                <div className="col-sm-12">
                                    <div className="table-responsive saved-result-table">
                                        <table className="table table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Hierarchy Filter</th>
                                                    <th>Status</th>
                                                    <th>Option</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    AllHierarchy.map((item, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                {item.enable === true ?
                                                                    <>
                                                                        <td>{item.name}</td>
                                                                        <td>
                                                                            <div className="form-check form-switch mt-3">
                                                                                <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" value={item.active}
                                                                                    onChange={(e) => { valueChangeHandler(i, item.active); }}
                                                                                    checked={item.active} />
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <select
                                                                                className="form-select"
                                                                                value={item.selected_option}
                                                                                onChange={
                                                                                    (e) => { BrandHandler(i, e.target.value) }
                                                                                }
                                                                            >
                                                                                {item.options.map((data) => {
                                                                                    return (
                                                                                        <option
                                                                                            key={data.key}
                                                                                            value={data.key}
                                                                                        >
                                                                                            {data.value}
                                                                                        </option>
                                                                                    );
                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                    </> : null
                                                                }
                                                            </tr>
                                                        );
                                                    })
                                                }

                                            </tbody>
                                        </table>




                                    </div>
                                </div>
                            </div>

                            <div className="row mb-5">
                                <div className="col-6 ">
                                    <button className=" btn btn-modal-primary" style={{float: "right"}} onClick={(e) => {
                                        HandleSubmit()

                                    }}>Save</button>
                                </div>
                                <div className="col-6">
                                    <button
                                        className=" btn btn-modal-secondary"
                                        data-bs-dismiss="modal"
                                        onClick={()=>{
                                            props.setUserAccess({})
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserAccessPopUp;
