import React from 'react'

// https://medium.com/geekculture/creating-multi-select-dropdown-with-checkbox-in-react-792ff2464ef3

const MeasurementSummary = (props) => {
	return (
		parseInt(props.ShowSummary) === 1 ?
			<div className={props.ZoomIn ? "col-sm-12 col-md-12 col-lg-12 col-xl-2" : "col-sm-12 col-md-12 col-lg-12 col-xl-4"}>
				<div className="card custom-summary mb-0" style={{ height:props.DivHeight+"px"}}>
					<div className="card-body p-0">
						<div className="card-3">

							<div className="custom-footer active mb-1">
								<span className="footer-title cursor-pointer"
									style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<h3 className="data-spends my-2 px-2">Summary</h3>
								</span>
							</div>

							<div className="custom-body" style={{ overflowX: 'hidden', height: '100%', overflowY: 'auto' }}>

								<div className="row mt-2">
									<div className="col-sm-12 p-0" style={{ overflow: 'auto', height: 'auto' }}>
										<ol>
											{props.Summary.map((item) => {
												return (
													<li key={item} className="spends-list-li">
														<p>{item}</p>
													</li>
												);
											})}
										</ol>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> :
			null
	)
}

export default MeasurementSummary